export default {
  DemandCenterList: (name) => ([
    {
      name: name + 'AllList',
      title: '所有服务需求'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'Dispatch',
      title: '派工'
    },
    {
      name: name + 'AddPlan',
      title: '填报'
    },
    {
      name: name + 'Send',
      title: '转交'
    },
    {
      name: name + 'ApprovalPlan',
      title: '审批出差计划'
    },
    {
      name: name + 'ModifyPlan',
      title: '修改出差计划'
    },
    {
      name: name + 'Reset',
      title: '退回'
    },
    {
      name: name + 'Skip',
      title: '跳过'
    },
    {
      name: name + 'Start',
      title: '开始执行'
    },
    {
      name: name + 'StartAgain',
      title: '继续执行'
    },
    {
      name: name + 'Finish',
      title: '已完成'
    },
    {
      name: name + 'Init',
      title: '重开'
    },
    {
      name: name + 'Withdraw',
      title: '撤回'
    },
    {
      name: name + 'Approval',
      title: '审批'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Save',
      title: '提交'
    },
    {
      name: name + 'UploadPlan',
      title: '上传技术方案'
    },
    {
      name: name + 'UploadPlanAgain',
      title: '继续上传'
    },
    {
      name: name + 'Write',
      title: '填写'
    },
    {
      name: name + 'DelRequirement',
      title: '删除需求'
    }
  ]),
  MaintenancePlan: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'MaintenancePlanExport',
      title: '全部导出'
    },
    {
      name: name + 'PassChecks',
      title: '批量跳过'
    },
    {
      name: name + 'Print',
      title: '打印工单'
    },
    {
      name: name + 'Execu',
      title: '执行'
    },
    {
      name: name + 'Pass',
      title: '跳过'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'DelWorkOrder',
      title: '删除工单'
    }
  ]),
  SpotCheckPlan: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'MaintenancePlanExport',
      title: '全部导出'
    },
    {
      name: name + 'PassChecks',
      title: '批量跳过'
    },
    {
      name: name + 'Print',
      title: '打印工单'
    },
    {
      name: name + 'Execu',
      title: '执行'
    },
    {
      name: name + 'Pass',
      title: '跳过'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'DelWorkOrder',
      title: '删除工单'
    }
  ]),
  RepairsPlan: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'MaintenancePlanExport',
      title: '全部导出'
    },
    {
      name: name + 'Pass',
      title: '跳过'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'DelWorkOrder',
      title: '删除工单'
    }
  ]),
  InstallEquipment: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'MaintenancePlanExport',
      title: '全部导出'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'DelWorkOrder',
      title: '删除工单'
    }
  ]),
  Reform: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'MaintenancePlanExport',
      title: '全部导出'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'DelWorkOrder',
      title: '删除工单'
    }
  ]),
  EnterpriseFilesList: (name) => ([
    {
      name: name + 'Add',
      title: '上传'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Download',
      title: '下载'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]),
  FailureExperienceList: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]),
  ServiceOrderList: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]),
  DailyReportList: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'SuperPermission',
      title: '删除/修改'
    },
  ]),

}
