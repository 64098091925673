import callButtons from './page-buttons/call.buttons'
export default {
    path: 'call',
    name: 'call',
    component: () => import('@/layout/blank.vue'),
    meta: { title: '异常管理', icon: require('@/assets/l_c_S_images/全场呼叫.svg'), activeMenu: 'call' },
    redirect: '/call/manage/search',
    children: [
      {
        path: 'manage',
        name: 'callManage',
        component: () => import('@/layout/blank.vue'),
        meta: { title: '全场呼叫', activeMenu: 'manage' },
        redirect: '/call/manage/search',
        children: [
          {
            path: 'search',
            name: 'callSearch',
            component: () => import('@/views/call/search/search.vue'),
            meta: { title: '呼叫查询', activeMenu: 'search', buttons: callButtons.callSearch('callSearch') }
          },
          // {
          //   path: 'classify',
          //   name: 'callClassify',
          //   component: () => import('@/views/call/classify/classify.vue'),
          //   meta: { title: '呼叫类型', activeMenu: 'classify', buttons: callButtons.callClassify('callClassify') }
          // }
        ]
      },
      {
        path: 'exception-handle',
        name: 'ExceptionHandle',
        component: () => import('@/layout/blank.vue'),
        meta: { title: '异常处理', activeMenu: 'manage' },
        redirect: '/call/exception-handle/exception-list',
        children: [
          {
            path: 'exception-list',
            name: 'ExceptionList',
            component: () => import('@/views/call/exception-handle/exception-handle.vue'),
            meta: { title: '异常列表', activeMenu: 'exception-list', buttons: callButtons.ExceptionList('ExceptionList') }
          },
          {
            path: 'detail',
            name: 'ExceptionDetail',
            component: () => import('@/views/call/exception-handle/detail.vue'),
            meta: { title: '异常详情', activeMenu: 'exception-list', noCache: true, nb: true },
            hidden: true
          },
          {
            path: 'exception-task-list',
            name: 'ExceptionTaskList',
            component: () => import('@/views/call/exception-handle/exception-task.vue'),
            meta: { title: '异常任务', activeMenu: 'exception-task-list'}
          },
          {
            path: 'task-detail',
            name: 'taskDetail',
            component: () => import('@/views/call/exception-handle/detail.vue'),
            meta: { title: '异常任务详情', activeMenu: 'exception-task-list', noCache: true, nb: true },
            hidden: true
          },
          // {
          //   path: 'exception-setting',
          //   name: 'ExceptionSetting',
          //   component: () => import('@/views/call/exception-setting/exception-setting.vue'),
          //   meta: {noCache:true, title: '异常设置', activeMenu: 'exception-setting', buttons: callButtons.ExceptionSetting('ExceptionSetting') }
          // },
          // {
          //   path: 'self-abnormality',
          //   name: 'SelfAbnormality',
          //   component: () => import('@/layout/blank.vue'),
          //   meta: { title: '我的异常', activeMenu: 'SelfAbnormality', canLink: true },
          //   redirect: '/call/exception-handle/self-abnormality/list',
          //   children: [
          //     {
          //       path: 'list',
          //       name: 'SelfAbnormalityList',
          //       component: () => import('@/views/call/self-abnormality/self-abnormality.vue'),
          //       meta: { title: '异常列表', noCache: false, activeMenu: 'SelfAbnormality' },
          //       hidden: true
          //     },
          //     {
          //       path: 'addCall',
          //       name: 'SelfAbnormalityAddCall',
          //       component: () => import('@/views/call/self-abnormality/detail/addCall.vue'),
          //       meta: { title: '发起呼叫', noCache: true, activeMenu: 'SelfAbnormality', nb: true },
          //       hidden: true
          //     },
          //     {
          //       path: 'detail',
          //       name: 'SelfAbnormalityDetail',
          //       component: () => import('@/views/call/self-abnormality/detail/detail.vue'),
          //       meta: { title: '详情', noCache: true, activeMenu: 'SelfAbnormality', nb: true },
          //       hidden: true
          //     },
          //     {
          //       path: 'callJudge',
          //       name: 'CallJudge',
          //       component: () => import('@/views/call/self-abnormality/detail/callJudge.vue'),
          //       meta: { title: '异常判定', noCache: true, activeMenu: 'SelfAbnormality', nb: true },
          //       hidden: true
          //     },
          //     {
          //       path: 'callHandle',
          //       name: 'CallHandle',
          //       component: () => import('@/views/call/self-abnormality/detail/callHandle.vue'),
          //       meta: { title: '异常处理', noCache: true, activeMenu: 'SelfAbnormality', nb: true },
          //       hidden: true
          //     },
          //     {
          //       path: 'callCheck',
          //       name: 'CallCheck',
          //       component: () => import('@/views/call/self-abnormality/detail/callCheck.vue'),
          //       meta: { title: '开始验收', noCache: true, activeMenu: 'SelfAbnormality', nb: true },
          //       hidden: true
          //     }
          //   ]
          // },
        ]
      }
    ]
}
