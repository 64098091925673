var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "none" }, attrs: { id: _vm.commonIds } },
    _vm._l(_vm.codes, function(item, index) {
      return _c("div", { key: index, staticStyle: { "padding-top": "10px" } }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "inline-flex",
              "justify-content": "flex-start"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { width: "120px" } },
              [
                _c("VueQr", {
                  attrs: {
                    "correct-level": 3,
                    "auto-color": false,
                    "color-dark": "#000000",
                    text: item.batchNumber,
                    size: 140,
                    margin: 0
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-left": "26px", "text-align": "left" } },
              [
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v("物料名称：" + _vm._s(item.materialsName))
                ]),
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v("物料编码：" + _vm._s(item.materialsCode))
                ]),
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v("物料规格：" + _vm._s(item.materialsSpecifications))
                ]),
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v("生产日期：" + _vm._s(_vm.times))
                ]),
                _c("div", { staticStyle: { "font-size": "18px" } }, [
                  _vm._v("批次码：" + _vm._s(item.batchNumber))
                ]),
                item.memberCode === "bbelc"
                  ? _c("div", { staticStyle: { "font-size": "18px" } }, [
                      _vm._v("工厂名称：上海工厂")
                    ])
                  : _vm._e(),
                item.memberCode === "bbwz"
                  ? _c("div", { staticStyle: { "font-size": "18px" } }, [
                      _vm._v("工厂名称：温州工厂")
                    ])
                  : _vm._e()
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { "font-size": "15px" } },
          _vm._l(item.reportDatas, function(row) {
            return _c(
              "span",
              {
                key: row.name,
                staticStyle: { "font-size": "15px", "text-align": "left" }
              },
              _vm._l(row.fromData, function(i, idx) {
                return _c("span", { key: i.id }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v(_vm._s(i.key) + "：" + _vm._s(_vm.fixData(i.value)))
                  ]),
                  idx === 3 ? _c("span", [_c("br")]) : _vm._e(),
                  idx < row.fromData.length - 2
                    ? _c("span", [_vm._v("；")])
                    : _vm._e()
                ])
              }),
              0
            )
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }