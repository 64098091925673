export default {
    ChangeManagementList: (name) => [
      {
        name: name + 'List',
        title: '列表'
      },
      {
        name: name + 'Add',
        title: '新增'
      },
      {
        name: name + 'Detail',
        title: '详情'
      },
      {
        name: name + 'Modify',
        title: '编辑'
      },
      {
        name: name + 'Del',
        title: '删除'
      }
    ]
   
  }
  