import qualityButtons from './page-buttons/quality.buttons'
import informationButtons from './page-buttons/information.buttons'

export default {
  path: 'quality',
  name: 'quality',
  component: () => import('@/layout/blank.vue'),
  meta: {
    title: '质量管理',
    icon: require('@/assets/l_c_S_images/质量管理.svg'),
    activeMenu: 'quality'
  },
  redirect: '/quality/quality-plan-info/quality-template',
  children: [
    {
      path: 'quality-infos',
      name: 'qualityInfos',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '质检管理', activeMenu: 'quality-infos' },
      redirect: '/quality/quality-infos/quality-inspection-order/list',
      children: [
        // {
        //   path: 'waiting-board',
        //   name: 'WaitingBoard',
        //   component: () => import('@/views/qualityManagement/waitingBoard.vue'),
        //   meta: { noCache: true, title: '待检看板', activeMenu: 'waiting-board'}
        // },
        // {
        //   path: 'quality-inspection-order',
        //   name: 'qualityInspectionOrder',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '质检任务', activeMenu: 'quality-inspection-order', canLink: true },
        //   redirect: '/quality/quality-infos/quality-inspection-order/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'qualityInspectionOrderList',
        //       component: () => import('@/views/quality/quality-inspection-order/index.vue'),
        //       meta: { roleTitle: '质检单列表', title: '', noCache: false, activeMenu: 'quality-inspection-order', buttons: qualityButtons.qualityInspectionOrderList('qualityInspectionOrderList') },
        //       hidden: true
        //     },
        //     {
        //       path: 'detail',
        //       name: 'qualityInspectionOrderDetail',
        //       component: () => import('@/views/quality/quality-inspection-order/detail/detail.vue'),
        //       meta: { title: '质检单详情', noCache: false, activeMenu: 'quality-inspection-order', nb: true },
        //       hidden: true
        //     }
        //   ]
        // },

        {
          path: 'process-inspection',
          name: 'ProcesInspection',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '过程检', activeMenu: 'process-inspection', canLink: true },
          redirect: '/quality/quality-infos/process-inspection/list',
          children: [
            {
              path: 'list',
              name: 'qualityInspectionOrderList',
              component: () => import('@/views/quality/quality-inspection-order/index.vue'),
              meta: {
                roleTitle: '过程质检单列表',
                title: '',
                noCache: false,
                activeMenu: 'process-inspection',
                buttons: qualityButtons.qualityInspectionOrderList('qualityInspectionOrderList'),
                nameFlage:'process'
              }
            },
            {
              path: 'detail',
              name: 'qualityInspectionOrderDetail',
              component: () => import('@/views/quality/quality-inspection-order/detail/detail.vue'),
              meta: { title: '过程质检单详情', noCache: false, activeMenu: 'process-inspection', nb: true }
            }
          ]
        },

        {
          path: 'shipment-inspection',
          name: 'ShipmentInspection',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '出货检', activeMenu: 'shipment-inspection', canLink: true },
          redirect: '/quality/quality-infos/shipment-inspection/list',
          children: [
            {
              path: 'list',
              name: 'qualityInspectionOrderList',
              component: () => import('@/views/quality/quality-inspection-order/index.vue'),
              meta: {
                roleTitle: '出货质检单列表',
                title: '',
                noCache: false,
                activeMenu: 'shipment-inspection',
                buttons: qualityButtons.qualityInspectionOrderList('qualityInspectionOrderList'),
                nameFlage:'outProduct'
              }
            },
            {
              path: 'detail',
              name: 'qualityInspectionOrderDetail',
              component: () => import('@/views/quality/quality-inspection-order/detail/detail.vue'),
              meta: { title: '出货质检单详情', noCache: false, activeMenu: 'shipment-inspection', nb: true }
            }
          ]
        },
        {
          path: 'incoming-inspection',
          name: 'IncomingInspection',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '来料检', activeMenu: 'incoming-inspection', canLink: true },
          redirect: '/quality/quality-infos/incoming-inspection/list',
          children: [
            {
              path: 'list',
              name: 'qualityInspectionOrderList',
              component: () => import('@/views/quality/quality-inspection-order/index.vue'),
              meta: {
                roleTitle: '来料质检单列表',
                title: '',
                noCache: false,
                activeMenu: 'incoming-inspection',
                buttons: qualityButtons.qualityInspectionOrderList('qualityInspectionOrderList'),
                nameFlage:'incomming'
              }
            },
            {
              path: 'detail',
              name: 'qualityInspectionOrderDetail',
              component: () => import('@/views/quality/quality-inspection-order/detail/detail.vue'),
              meta: { title: '来料质检单详情', noCache: false, activeMenu: 'incoming-inspection', nb: true }
            }
          ]
        },
        {
          path: 'quality-task',
          name: 'QualityTask',
          component: () => import('@/layout/blank.vue'),
          redirect: '/quality/quality-infos/quality-task/quality-task-list',
          meta: { noCache: true, title: '质检任务', activeMenu: 'quality-task', canLink: true },
          children: [
            {
              path: 'quality-task-list',
              name: 'QualityTaskList',
              component: () => import('@/views/qualityManagement/quality-task/list.vue'),
              meta: { noCache: true, title: '任务列表', activeMenu: 'quality-task', buttons: qualityButtons.qualityTask('QualityTaskList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'QualityTaskDetail',
              component: () => import('@/views/qualityManagement/quality-task/detail.vue'),
              meta: { title: '详情', noCache: false, activeMenu: 'quality-task', nb: true },
              hidden: true
            }
          ]
        }

      ]
    },

    {
      path: 'quality-defective-product',
      name: 'qualityDefectiveProduct',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '不良品处理', activeMenu: 'quality-defective-product' },
      redirect: '/quality/quality-defective-product/repair-record',
      children: [
        {
          path: 'defective-product-judge',
          name: 'DefectiveProductJudge',
          component: () => import('@/layout/blank.vue'),
          redirect: '/quality/quality-defective-product/defective-product-judge/list',
          meta: { noCache: true, title: '不良品判定', activeMenu: 'defective-product-judge', canLink: true },
          children: [
            {
              path: 'list',
              name: 'DefectiveProductJudgeList',
              component: () => import('@/views/qualityManagement/defective-product-judge/defective-product-judge.vue'),
              meta: { noCache: false, title: '列表', activeMenu: 'defective-product-judge', buttons: qualityButtons.defectiveProductJudge('DefectiveProductJudgeList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'DefectiveProductJudgeDetail',
              component: () => import('@/views/qualityManagement/defective-product-judge/detail.vue'),
              meta: { title: '详情', noCache: true, activeMenu: 'defective-product-judge', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'defective-product-task',
          name: 'DefectiveProductTask',
          component: () => import('@/layout/blank.vue'),
          redirect: '/quality/quality-defective-product/defective-product-task/list',
          meta: { noCache: true, title: '不良品任务', activeMenu: 'defective-product-task', canLink: true },
          children: [
            {
              path: 'list',
              name: 'DefectiveProductTaskList',
              component: () => import('@/views/qualityManagement/defective-product-judge/defective-product-task.vue'),
              meta: { noCache: false, title: '列表', activeMenu: 'defective-product-task', buttons: qualityButtons.defectiveProductTask('DefectiveProductTaskList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'DefectiveProductTaskDetail',
              component: () => import('@/views/qualityManagement/defective-product-judge/detail.vue'),
              meta: { title: '详情', noCache: true, activeMenu: 'defective-product-task', nb: true },
              hidden: true
            }
          ]
        },
    {
      path: 'repair-record',
      name: 'repairRecord',
      component: () => import('@/views/quality/repair-records/index.vue'),
      meta: { title: '质检返修', activeMenu: 'repair-Record', buttons: qualityButtons.repairRecord('repairRecord') }
    },
    {
      path: 'report-waste-record',
      name: 'reportWasteRecord',
      component: () => import('@/views/quality/report-waste-record/index.vue'),
      meta: { title: '报废管理', activeMenu: 'report-waste-record', buttons: qualityButtons.reportWasteRecord('reportWasteRecord') }
    },
    {
      path: 'unquality',
      name: 'unquality',
      component: () => import('@/views/quality/unquality/index'),
      meta: {
        title: '不合格品处理',
        icon: 'unquality',
        activeMenu: 'unquality',
        buttons: qualityButtons.unquality('unquality')
      }
    },
    {
      path: 'scrap',
      name: 'scrap',
      component: () => import('@/views/warehouse/scrap/scrap.vue'),
      meta: { noCache: false, title: '废品仓', activeMenu: 'scrap', buttons: qualityButtons.scrap('scrap') }
    }
  ]},

    // {
    //   path: 'quality-plan-info',
    //   name: 'qualityPlanInfo',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '质检方案配置', activeMenu: 'quality-plan-info' },
    //   redirect: '/quality/quality-plan-info/quality-template',
    //   children: [
    //     // {
    //     //   path: 'quality-template',
    //     //   name: 'Quality',
    //     //   component: () => import('@/views/quality/quality-template/index'),
    //     //   meta: {
    //     //     title: '质检模板',
    //     //     icon: 'quality-template',
    //     //     activeMenu: 'quality-template',
    //     //     buttons: qualityButtons.qualityTemplate('Quality')
    //     //   }
    //     // },
    //     // {
    //     //   path: 'quality-plan',
    //     //   name: 'quality-plan',
    //     //   component: () => import('@/views/quality/quality-plan/index'),
    //     //   meta: {
    //     //     title: '质检方案',
    //     //     icon: 'quality-template',
    //     //     activeMenu: 'quality-plan',
    //     //     buttons: qualityButtons.qualityPlan('quality-plan')
    //     //   }
    //     // },
    //     // {
    //     //   path: 'detail',
    //     //   name: 'quality-plan-detail',
    //     //   component: () => import('@/views/quality/quality-plan/component/detail'),
    //     //   meta: {
    //     //     title: '质检方案详情',
    //     //     noCache: false,
    //     //     activeMenu: 'quality-plan'
    //     //   },
    //     //   hidden: true
    //     // },
    //     // {
    //     //   path: 'inspection-methods',
    //     //   name: 'inspectionMethods',
    //     //   component: () => import('@/views/quality/inspection-methods/inspection-methods.vue'),
    //     //   meta: { title: '检验方法', icon: 'methods', activeMenu: 'inspection-methods', buttons: qualityButtons.inspectionMethods('inspectionMethods') }
    //     // },
    //     // {
    //     //   path: 'inspection-standard',
    //     //   name: 'inspectionStandard',
    //     //   component: () => import('@/views/quality/inspection-standard/inspection-standard.vue'),
    //     //   meta: { title: '检验标准', icon: 'standard', activeMenu: 'inspection-standard', buttons: qualityButtons.inspectionStandard('inspectionStandard') }
    //     // },
    //     // {
    //     //   path: 'inspection-project',
    //     //   name: 'inspectionProject',
    //     //   component: () => import('@/views/quality/inspection-project/inspection-project.vue'),
    //     //   meta: { title: '检验项目', icon: 'items', activeMenu: 'inspection-project', buttons: qualityButtons.inspectionProject('inspectionProject') }
    //     // },
    //     {
    //       path: 'inspection-project',
    //       name: 'InspectionProject',
    //       component: () => import('@/views/quality/inspection-project-new/inspection-project-new.vue'),
    //       meta: { title: '质检项目', icon: 'items', activeMenu: 'inspection-project', buttons: qualityButtons.inspectionProject('inspectionProject') }
    //     },
    //     {
    //       path: 'quality-inspection-plan',
    //       name: 'qualityInspectionPlan',
    //       component: () => import('@/layout/blank.vue'),
    //       meta: { title: '质检方案', activeMenu: 'quality-inspection-plan', canLink: true },
    //       redirect: '/quality/quality-plan-info/quality-inspection-plan/list',
    //       children: [
    //         {
    //           path: 'list',
    //           name: 'QualityPlanList',
    //           component: () => import('@/views/quality/inspection-plan/inspection-plan.vue'),
    //           meta: { roleTitle: '方案列表', title: '', noCache: false, activeMenu: 'quality-inspection-plan', buttons: qualityButtons.qualityInspectionPlanList('QualityPlanList') },
    //           hidden: true
    //         },
    //         {
    //           path: 'detail',
    //           name: 'QualityPlanDetail',
    //           component: () => import('@/views/quality/inspection-plan/detail/detail.vue'),
    //           meta: { title: '详情', noCache: false, activeMenu: 'quality-inspection-plan', nb: true },
    //           hidden: true
    //         }
    //       ]
    //     },
    //     // {
    //     //   path: 'inspection-items',
    //     //   name: 'inspectionItems',
    //     //   component: () => import('@/views/quality/inspection-items/inspection-items.vue'),
    //     //   meta: { title: '检验条目', icon: 'items', activeMenu: 'inspection-items', buttons: qualityButtons.inspectionItems('inspectionItems') }
    //     // },
    //     {
    //       path: 'defects',
    //       name: 'defectsManage',
    //       component: () => import('@/views/quality/defects/defects.vue'),
    //       meta: { title: '缺陷管理', icon: 'defects', activeMenu: 'defects', buttons: qualityButtons.defects('defectsManage') }
    //     }

    //   ]
    // },
    {
      path: 'all-code-tracing',
      name: 'allCodeTracing',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '追溯管理', activeMenu: 'all-code-tracing' },
      redirect: '/quality/all-code-tracing/product-batch-code-tracing/list',
      children: [
        {
          path: 'raw-material-batch-code-tracing',
          name: 'rawMaterialBatchCodeTracing',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '原材料追溯', activeMenu: 'raw-material-batch-code-tracing', canLink: true },
          redirect: '/quality/all-code-tracing/raw-material-batch-code-tracing/list',
          children: [
            {
              path: 'list',
              name: 'rawMaterialBatchCodeTracingList',
              component: () => import('@/views/quality/raw-material-batch-code-tracing/index'),
              meta: { roleTitle: '原材料追溯列表', title: '', noCache: false, activeMenu: 'raw-material-batch-code-tracing' },
              hidden: true
            },
            {
              path: 'detail',
              name: 'rawMaterialBatchCodeTracingDetail',
              component: () => import('@/views/quality/raw-material-batch-code-tracing/detail'),
              meta: { title: '详情', noCache: false, activeMenu: 'raw-material-batch-code-tracing', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'product-batch-code-tracing',
          name: 'productBatchCodeTracing',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '全景追溯', activeMenu: 'product-batch-code-tracing', canLink: true },
          redirect: '/quality/all-code-tracing/product-batch-code-tracing/list',
          children: [
            {
              path: 'list',
              name: 'productBatchCodeTracingList',
              component: () => import('@/views/quality/product-batch-code-tracing/index'),
              meta: { roleTitle: '全景追溯列表', title: '', noCache: false, activeMenu: 'product-batch-code-tracing' },
              hidden: true
            },
            {
              path: 'detail',
              name: 'productBatchCodeTracingDetail',
              component: () => import('@/views/quality/product-batch-code-tracing/detail'),
              meta: { title: '详情', noCache: false, activeMenu: 'product-batch-code-tracing', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'finished-product-tracing',
          name: 'finishedProductTracing',
          component: () => import('@/views/quality/finished-product-tracing/index.vue'),
          meta: { title: '成品追溯', activeMenu: 'finished-product-tracing', buttons: qualityButtons.finishedProductTracing('finishedProductTracing') }
        },
      ]
    },
    // 质量建模
    {
      path: 'quality-info',
      name: 'QualityInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '基础数据', activeMenu: 'quality-info' },
      redirect: '/quality/quality-info/inspection-project',
      children: [
        {
          path: 'inspection-project',
          name: 'InspectionProject',
          component: () => import('@/views/quality/inspection-project-new/inspection-project-new.vue'),
          meta: { title: '质检项', icon: 'items', activeMenu: 'inspection-project', buttons: qualityButtons.inspectionProject('inspectionProject') }
        },
        {
          path: 'quality-inspection-plan',
          name: 'qualityInspectionPlan',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '质检方案', activeMenu: 'quality-inspection-plan', canLink: true },
          redirect: '/quality/quality-info/quality-inspection-plan/list',
          children: [
            {
              path: 'list',
              name: 'QualityPlanList',
              component: () => import('@/views/quality/inspection-plan/inspection-plan.vue'),
              meta: { roleTitle: '方案列表', title: '', noCache: false, activeMenu: 'quality-inspection-plan', buttons: qualityButtons.qualityInspectionPlanList('QualityPlanList') },
              hidden: true
            },
            {
              path: 'detail',
              name: 'QualityPlanDetail',
              component: () => import('@/views/quality/inspection-plan/detail/detail.vue'),
              meta: { title: '详情', noCache: false, activeMenu: 'quality-inspection-plan', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'defects-info',
          name: 'DefectsInfo',
          component: () => import('@/views/quality/defects/defects.vue'),
          meta: {
            title: '缺陷项',
            icon: 'defects',
            activeMenu: 'defects-info',
            buttons: qualityButtons.baseCommonButtons('DefectsInfo'),
            nameFlage:'DefectsItem'
          }
        },
        {
          path: 'defects-reason',
          name: 'DefectsReason',
          component: () => import('@/views/quality/defects/defects.vue'),
          meta: {
            title: '缺陷原因',
            icon: 'defects',
            activeMenu: 'defects-reason',
            buttons: qualityButtons.baseCommonButtons('DefectsReason'),
            nameFlage:'DefectsReason'
          }
        },
        {
          path: 'scarp-reason',
          name: 'scarpReason',
          component: () => import('@/views/setting/params/scarp-reason/scarp-reason.vue'),
          meta: { title: '报废原因', icon: 'flight-managment', activeMenu: 'scarp-reason', buttons: informationButtons.scarpReason('scarpReason') }
        },
        // {
        //   path: 'model',
        //   name: 'model',
        //   component: () => import('@/views/information/warehouse/warehouse.vue'),
        //   meta: { title: '仓库定义', activeMenu: 'model', buttons: informationButtons.model('model') }
        // },
        // {
        //   path: 'package-info',
        //   name: 'packageInfo',
        //   component: () => import('@/views/information/warehouse/package/package.vue'),
        //   meta: { title: '包装容器', activeMenu: 'package-info', buttons: informationButtons.packageInfo('packageInfo') }
        // }
      ]
    },
  ]
}
