const userCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'Status',
    title: '启/停用'
  }
]
const defaultCommon = (name) => [
  {
    name: name + 'List',
    title: '列表'
  },
  {
    name: name + 'Add',
    title: '新增'
  },
  {
    name: name + 'Copy',
    title: '复制'
  },
  {
    name: name + 'Modify',
    title: '修改'
  },
  {
    name: name + 'Del',
    title: '删除'
  },
  {
    name: name + 'Status',
    title: '启/停用'
  },
  {
    name: name + 'Export',
    title: '导出'
  }
]
export default {
  user: (name = 'user') => {
    const base = [
      {
        name: name + 'UserGroupList',
        title: '用户组',
        children: [...userCommon(name, 'UserGroup'), {
          name: name + 'UserGroupExport',
          title: '导出'
        }]
      }
    ]
    if (sessionStorage.getItem('memberCode') === 'reagold') {
      base.push({
        name: name + 'AUserList',
        title: '用户',
        children: [...userCommon(name, 'User'),
          {
            name: name + 'UserSetStation',
            title: '设置工位'
          },
          {
            name: name + 'UserImport',
            title: '导入'
          },
          {
            name: name + 'UserExport',
            title: '导出'
          }]
      })
    } else if (sessionStorage.getItem('memberCode') === 'xwhj-sh') {
      base.push({
        name: name + 'AUserList',
        title: '用户',
        children: [...userCommon(name, 'User'),
          {
            name: name + 'UserImport',
            title: '导入'
          },
          {
            name: name + 'UserExport',
            title: '导出'
          }]
      })
    } else {
      base.push(
        {
          name: name + 'AUserList',
          title: '用户',
          children: [...userCommon(name, 'User'),
            {
              name: name + 'UserSetStation',
              title: '设置工位'
            },
            {
              name: name + 'UserSetEquipment',
              title: '设置设备'
            },
            {
              name: name + 'UserImport',
              title: '导入'
            },
            {
              name: name + 'UserExport',
              title: '导出'
            }]
        }
      )
    }
    return base
  },
  role: (name = 'role') => {
    const bases = [
      {
        name: name + 'Role',
        title: '功能权限',
        children: [
          {
            name: name + 'RoleList',
            title: '列表'
          },
          {
            name: name + 'RoleAdd',
            title: '新增'
          },
          {
            name: name + 'RoleModify',
            title: '修改'
          },
          {
            name: name + 'RoleDel',
            title: '删除'
          },
          {
            name: name + 'RoleStatus',
            title: '启/停用'
          },
          {
            name: name + 'RoleExport',
            title: '导出'
          }
        ]
      },
      {
        name: name + 'BUserList',
        title: '关联用户',
        children: [
          {
            name: name + 'UserSetStations',
            title: '设置工位'
          },
          {
            name: name + 'UserSetEquipments',
            title: '设置设备'
          },
          {
            name: name + 'UserModify',
            title: '修改'
          },
          {
            name: name + 'UserDel',
            title: '删除'
          }
        ]
      }
    ]
    return bases
  },
  shift: (name) => defaultCommon(name),
  log: (name = 'log') => {
    return sessionStorage.getItem('memberCode') === 'mubai' ? [
      {
        name: name + 'list',
        title: '基本设置'
      },
      {
        name: name + 'Add',
        title: '新增日志'
      },
      {
        name: name + 'Modify',
        title: '修改日志'
      },
      {
        name: name + 'Del',
        title: '删除日志'
      }
    ] : []
  },
  framework: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  MessageNotificate: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]
}
