var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "none" }, attrs: { id: _vm.commonIds } },
    _vm._l(_vm.codes, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticStyle: { "padding-top": "5px", display: "flex", margin: "5px" }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "50%",
                border: "2px solid #000",
                "border-radius": "5px",
                "margin-right": "10px",
                padding: "5px 10px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("产品名称：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsName))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("产品编码：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsCode))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("产品规格：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsSpecifications))
                  ])
                ]
              ),
              _vm._m(0, true),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("生产日期：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(_vm.times))
                  ])
                ]
              ),
              _vm._m(1, true),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left", flex: "1" } },
                    [
                      _c("VueQr", {
                        attrs: {
                          "correct-level": 3,
                          "auto-color": false,
                          "color-dark": "#000000",
                          text: item.batchNumber,
                          size: 40,
                          margin: 0
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left", flex: "2" } },
                    [
                      _c("VueBarcode", {
                        attrs: {
                          value: item.batchNumber,
                          width: 0.8,
                          text: "",
                          height: 30,
                          "font-size": 12,
                          margin: 0,
                          font: "PingFangSC-Regular",
                          "text-align": "left"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "50%",
                border: "2px solid #000",
                "border-radius": "5px",
                padding: "5px 10px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("产品名称：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsName))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("产品编码：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsCode))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("产品规格：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsSpecifications))
                  ])
                ]
              ),
              _vm._m(2, true),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-bottom": "5px",
                    "text-align": "left"
                  }
                },
                [
                  _c("span", { staticStyle: { width: "90px" } }, [
                    _vm._v("生产日期：")
                  ]),
                  _c("span", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(_vm.times))
                  ])
                ]
              ),
              _vm._m(3, true),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "flex-start"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left", flex: "1" } },
                    [
                      _c("VueQr", {
                        attrs: {
                          "correct-level": 3,
                          "auto-color": false,
                          "color-dark": "#000000",
                          text: item.batchNumber,
                          size: 40,
                          margin: 0
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "left", flex: "2" } },
                    [
                      _c("VueBarcode", {
                        attrs: {
                          value: item.batchNumber,
                          width: 0.8,
                          text: "",
                          height: 30,
                          "font-size": 12,
                          margin: 0,
                          font: "PingFangSC-Regular",
                          "text-align": "left"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "18px",
          "margin-bottom": "5px",
          "text-align": "left"
        }
      },
      [
        _c("span", { staticStyle: { width: "90px" } }, [_vm._v("装箱数量：")]),
        _c("span", { staticStyle: { "font-size": "18px" } }, [_vm._v("1")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "18px",
          "margin-bottom": "5px",
          "text-align": "left"
        }
      },
      [
        _c("span", { staticStyle: { width: "90px" } }, [_vm._v("检验结果：")]),
        _c("span", { staticStyle: { "font-size": "18px" } })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "18px",
          "margin-bottom": "5px",
          "text-align": "left"
        }
      },
      [
        _c("span", { staticStyle: { width: "90px" } }, [_vm._v("装箱数量：")]),
        _c("span", { staticStyle: { "font-size": "18px" } }, [_vm._v("1")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "18px",
          "margin-bottom": "5px",
          "text-align": "left"
        }
      },
      [
        _c("span", { staticStyle: { width: "90px" } }, [_vm._v("检验结果：")]),
        _c("span", { staticStyle: { "font-size": "18px" } })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }