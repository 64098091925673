import planButtons from './page-buttons/plan.buttons'
export default {
  path: 'sales',
  name: 'sales',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '销售管理', icon: require('@/assets/l_c_S_images/销售管理.svg'), activeMenu: 'sales' },
  redirect: '/sales/sales-manage',
  children: [
    {
      path: 'sales-manage',
      name: 'salesManage',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '销售管理', activeMenu: 'sales-manage' },
      redirect: '/sales/sales-manage',
      children: [
        {
          path: 'sales-order-info',
          name: 'salesOrderInfo',
          component: () => import('@/layout/blank.vue'),
          redirect: '/sales/sales-manage/sales-order-info/sales-order-list',
          meta: { noCache: true, title: '销售订单', activeMenu: 'sales-order-info', canLink: true },
          children: [
            {
              path: 'sales-order-list',
              name: 'salesOrderList',
              component: () => import('@/views/salesOrder/index.vue'),
              meta: { noCache: true, title: '销售订单', activeMenu: 'sales-order-info', buttons: planButtons.salesOrder('salesOrderList') },
              hidden: true
            },
            {
              path: 'sales-order-add',
              name: 'salesOrderAdd',
              component: () => import('@/views/salesOrder/add.vue'),
              meta: { noCache: false, title: '新增订单', activeMenu: 'sales-order-info' },
              hidden: true
            },
            {
              path: 'sales-order-detail',
              name: 'salesOrderDetail',
              component: () => import('@/views/salesOrder/detail.vue'),
              meta: { noCache: true, title: '订单详情', activeMenu: 'sales-order-info' },
              hidden: true
            },
          ]
        },
        {
          path: 'reserve-stock-order-info',
          name: 'reserveStockOrderInfo',
          component: () => import('@/layout/blank.vue'),
          redirect: '/sales/sales-manage/reserve-stock-order-info/reserve-stock-order-list',
          meta: { noCache: true, title: '备库单', activeMenu: 'reserve-stock-order-info', canLink: true},
          children: [
            {
              path: 'reserve-stock-order-list',
              name: 'reserveStockOrderList',
              component: () => import('@/views/reserveStockOrder/index.vue'),
              meta: { noCache: true, title: '备库单', activeMenu: 'reserve-stock-order-info', buttons: planButtons.reserveStockOrder('reserveStockOrderList') },
              hidden: true
            },
            {
              path: 'reserve-stock-order-add',
              name: 'reserveStockOrderAdd',
              component: () => import('@/views/reserveStockOrder/add.vue'),
              meta: { noCache: false, title: '新增备库单', activeMenu: 'reserve-stock-order-info' },
              hidden: true
            },
            {
              path: 'reserve-stock-order-detail',
              name: 'reserveStockOrderDetail',
              component: () => import('@/views/reserveStockOrder/detail.vue'),
              meta: { noCache: true, title: '备库单详情', activeMenu: 'reserve-stock-order-info' },
              hidden: true
            },
          ]
        }
      ]
    },

  ]
}
