import statisticsCard from '@/views/statistics-board/board-manage/all-statistics/statistics-card-copy'
import saleStatisticsCard from '@/views/statistics-board/board-manage/all-statistics/sale-statistics-card'

export default {
  authBoard: () => {
    if (sessionStorage.getItem('memberCode') === 'bbelc') {
      return [
        {
          index: 0,
          name: 'bbelc0',
          title: '包纸车间看板'
        },
        {
          index: 1,
          name: 'bbelc1',
          title: '浸漆车间看板'
        },
        {
          index: 2,
          name: 'bbelc2',
          title: '铁芯车间看板'
        },
        {
          index: 3,
          name: 'bbelc3',
          title: '总装车间看板'
        },
        {
          index: 4,
          name: 'bbelc4',
          title: '发货区看板'
        },
        {
          index: 5,
          name: 'bbelc5',
          title: '绕线车间看板'
        }
      ]
    }
    return []
  },
  statisticsBoard: (name = 'operatingReports') => {
    let statistics = statisticsCard.map(item => ({
      name: name + item.key,
      title: item.name
    }))
    if (!['mubai', 'bbelc', 'qingchu', 'jinshi', 'demo'].includes(sessionStorage.getItem('memberCode'))) {
      statistics = statistics.filter(item => item.name !== name + 'equipment')
    }
    if (!['mubai', 'reagold', 'kiki', 'qingchu', 'newfocus', 'demo'].includes(sessionStorage.getItem('memberCode'))) {
      statistics = statistics.filter(item => item.name !== name + 'qualityInspection')
    }
    return statistics
  },
  saleStatisticsBoard: (name = 'operatingReports') => {
    const statistics = saleStatisticsCard.map(item => ({
      name: name + item.key,
      title: item.name
    }))
    return statistics
  },
  allBoardGroup: (name = 'allBoardGroup') => {
    const base = []
    if (sessionStorage.getItem('memberCode') === 'bbelc') {
      base.push(
        {
          index: 11,
          name: name + 'Production',
          title: '生产车间看板'
        },
        {
          index: 12,
          name: name + 'Call',
          title: '异常看板'
        },
        {
          index: 13,
          name: name + 'Quanlity',
          title: '质检看板'
        },
        {
          index: 14,
          name: name + 'Equipment',
          title: '设备看板'
        },
        {
          index: 15,
          name: name + 'Warehouse',
          title: '仓库看板'
        },
        {
          index: 16,
          name: name + 'StockBoard',
          title: '库存看板'
        },
        {
          index: 0,
          name: 'bbelc0',
          title: '包纸车间看板'
        },
        {
          index: 1,
          name: 'bbelc1',
          title: '浸漆车间看板'
        },
        {
          index: 2,
          name: 'bbelc2',
          title: '铁芯车间看板'
        },
        {
          index: 3,
          name: 'bbelc3',
          title: '总装车间看板'
        },
        {
          index: 4,
          name: 'bbelc4',
          title: '发货区看板'
        },
        {
          index: 5,
          name: 'bbelc5',
          title: '绕线车间看板'
        }
      )
    } else if (sessionStorage.getItem('memberCode') === 'newfocus') {
      base.push(
        {
          index: 11,
          name: name + 'Production',
          title: '生产车间看板'
        },
        {
          index: 13,
          name: name + 'Quanlity',
          title: '质检看板'
        },
        {
          index: 15,
          name: name + 'Warehouse',
          title: '仓库看板'
        }
      )
    } else if (sessionStorage.getItem('memberCode') === 'fsd') {
      base.push(
        {
          index: 11,
          name: name + 'Production',
          title: '生产车间看板'
        },
        {
          index: 12,
          name: name + 'Call',
          title: '异常看板'
        },
        {
          index: 13,
          name: name + 'Quanlity',
          title: '质检看板'
        },
        {
          index: 14,
          name: name + 'Equipment',
          title: '设备看板'
        },
        {
          index: 15,
          name: name + 'Warehouse',
          title: '仓库看板'
        },
        {
          index: 16,
          name: name + 'SafeBoard',
          title: '安全看板'
        }
      )
    } else {
      base.push(
        {
          index: 11,
          name: name + 'Production',
          title: '生产车间看板'
        },
        {
          index: 12,
          name: name + 'Call',
          title: '异常看板'
        },
        {
          index: 13,
          name: name + 'Quanlity',
          title: '质检看板'
        },
        {
          index: 14,
          name: name + 'Equipment',
          title: '设备看板'
        },
        {
          index: 15,
          name: name + 'Warehouse',
          title: '仓库看板'
        }
      )
    }
    return base
  }
}
