import purchaseButtons from './page-buttons/purchase.buttons'
import warehouseButtons from './page-buttons/warehouse.buttons'
export default {
  path: 'purchase',
  name: 'purchase',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '采购管理', icon: require('@/assets/l_c_S_images/采购管理.svg'), activeMenu: 'purchase' },
  redirect: '/purchase/purchase-manage',
  children: [
    {
      path: 'purchase-manage',
      name: 'purchaseManage',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '采购管理', activeMenu: 'purchase' },
      redirect: '/purchase/purchase-manage/purchase-apply',
      children: [
        {
          path: 'purchase-apply',
          name: 'purchaseApply',
          component: () => import('@/views/warehouse/purchase/purchase'),
          meta: { noCache: false, title: '采购申请', activeMenu: 'purchase', buttons: warehouseButtons.purchase('purchaseApply') }
        },
        {
          path: 'purchase-order-list',
          name: 'purchaseOrderList',
          component: () => import('@/views/purchaseManage/index.vue'),
          meta: { noCache: true, title: '采购订单', activeMenu: 'purchase-manage', buttons: purchaseButtons.purchaseOrder('purchaseOrderList') }
        },
        {
          path: 'certain-number',
          name: 'certainNumber',
          component: () => import('@/layout/blank.vue'),
          meta: {  noCache: false, title: '生产定额',nb: true, activeMenu: 'certain-number', canLink: true },
          redirect: '/purchase/purchase-manage/certain-number/certain-number-list',
          children: [
            {
              path: 'certain-number-list',
              name: 'certainNumberList',
              component: () => import('@/views/plans/certainNumber/certainNumber.vue'),
              meta: { noCache: false, roleTitle: '生产定额列表',nb: false, title: '', activeMenu: 'certain-number', },
              hidden: true
            },
            {
              path: 'certain-number-detail',
              name: 'certainNumberDetail',
              component: () => import('@/views/plans/certainNumber/certainNumberDetail.vue'),
              meta: { noCache: false, title: '生产定额详情',nb: true, activeMenu: 'certain-number',},
              hidden: true
            },
            {
              path: 'certain-number-add',
              name: 'certainNumberAdd',
              component: () => import('@/views/plans/certainNumber/certainNumberAdd.vue'),
              meta: { noCache: true, title: '新增生产定额',nb: true, activeMenu: 'certain-number',},
              hidden: true
            }
          ]
        },
        {
          path: 'purchase-order-add',
          name: 'purchaseOrderAdd',
          component: () => import('@/views/purchaseManage/add.vue'),
          meta: { noCache: true, title: '新增订单', activeMenu: 'purchase-manage' },
          hidden: true
        },
        {
          path: 'purchase-order-detail',
          name: 'purchaseOrderDetail',
          component: () => import('@/views/purchaseManage/detail.vue'),
          meta: { noCache: true, title: '订单详情', activeMenu: 'purchase-manage' },
          hidden: true
        }
      ]
    }
  ]
}
