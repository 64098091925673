
export default {
  data() {
    return {
      searchNums: {},
      isShow: true
    }
  },
  created() {
    this.$store.dispatch('user/searchIndexs', {})
  },
  methods: {
    async checkSearch(val) {
      this.isShow = val
      if (val) {
        this.$store.dispatch('user/searchIndexs', this.searchNums)
      }
    },
    fixNum(data) {
      this.searchNums[this.params.radio] = data.length
    }
  }
}
