const EquipmentMaintenanceCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'ViewRepair',
    title: '查看'
  },
  {
    name: name + radio + 'DealRepair',
    title: '处理维保'
  }
]

const maintenanceSettingCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Copy',
    title: '复制'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'Export',
    title: '导出'
  }
]

const projectSettingCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  }
]
export default {
  equipmentAccountList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    // {
    //   name: name + 'AddRepair',
    //   title: '新建维保任务'
    // },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'PrintCode',
      title: '打印设备条码'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  equipmentAccountDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Product',
      title: '生产详情'
    },
    {
      name: name + 'Repair',
      title: '维保详情'
    }
  ],
  EquipmentFault: (name) => [
    {
      name: name + 'AList',
      title: '列表'
    },
    {
      name: name + 'FixRepair',
      title: '取消报修'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  EquipmentRepair: (name) => [
    {
      name: name + 'AList',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  PartAccount: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'equipment',
      title: '关联设备'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'parts',
      title: '使用记录'
    },
    {
      name: name + 'purchase',
      title: '采购'
    }
  ],
  PartApply: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'BatchOperate',
      title: '批量操作'
    },
    {
      name: name + 'Add',
      title: '新建申请'
    },
    {
      name: name + 'Modify',
      title: '修改申请'
    },
    {
      name: name + 'Del',
      title: '删除申请'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'Submit',
      title: '提交'
    }
  ],
  PartNums: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  PartBills: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'BatchExport',
      title: '批量导出'
    }
  ],
  EquipmentMaintenance: (name) => [
    {
      name: name + 'AList',
      title: '全部',
      children: EquipmentMaintenanceCommon(name, '')
    },
    {
      name: name + 'BList',
      title: '开机生产',
      children: EquipmentMaintenanceCommon(name, '0')
    },
    {
      name: name + 'CList',
      title: '故障维修',
      children: EquipmentMaintenanceCommon(name, '1')
    }
  ],
  maintenanceSetting: (name) => [
    {
      name: name + 'AExperienceList',
      title: '维保经验',
      children: maintenanceSettingCommon(name, 'Experience')
    },
    {
      name: name + 'BFaultReasonList',
      title: '故障原因',
      children: maintenanceSettingCommon(name, 'FaultReason')
    },
    {
      name: name + 'CUrgencyLevelList',
      title: '紧急程度',
      children: maintenanceSettingCommon(name, 'UrgencyLevel')
    },
    {
      name: name + 'DEquipmentPositionList',
      title: '设备部位',
      children: maintenanceSettingCommon(name, 'EquipmentPosition')
    },
    {
      name: name + 'FlowSettingList',
      title: '流程设置',
      children: [
        {
          name: name + 'FlowSettingIsApproval',
          title: '故障报修是否需审核'
        },
        {
          name: name + 'FlowSettingIsValidate',
          title: '维修结束是否需验证'
        },
        {
          name: name + 'FlowSettingApprovalUser',
          title: '设备故障审批人员配置'
        }
      ]
    },
    {
      name: name + 'EMaintenanceProject',
      title: '保养项目',
      children: [
        ...maintenanceSettingCommon(name, 'MaintenanceProject'),
        {
          name: name + 'MaintenanceProjectImport',
          title: '导入'
        }
      ]
    },
    {
      name: name + 'FMaintenanceSetting',
      title: '保养配置',
      children: [
        ...maintenanceSettingCommon(name, 'MaintenanceSetting').slice(0, 5)
      ]
    }
  ],
  projectSetting: (name) => [
    {
      name: name + 'APointCheckItemList',
      title: '点巡检项基础',
      children: [
        ...projectSettingCommon(name, 'PointCheckItem'),
        {
          name: name + 'PointCheckItemImport',
          title: '导入'
        },
        {
          name: name + 'PointCheckItemExport',
          title: '导出'
        }
      ]
    },
    {
      name: name + 'BPointCheckTypeList',
      title: '点检类别配置',
      children: projectSettingCommon(name, 'PointCheckType')
    },
    {
      name: name + 'CInspectionList',
      title: '巡检配置',
      children: projectSettingCommon(name, 'Inspection')
    }
  ],
  inspectionPlan: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  inspectionPlanDetail: (name) => [
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'AddCheckItem',
      title: '新增检查项'
    }
  ],
  maintenancePlan: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  maintenancePlanDetail: (name) => [
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'AddCheckItem',
      title: '新增检查项'
    }
  ],
  inspectionBillsList: (name) => [
    {
      name: name + 'PassCheckItem',
      title: '批量跳过'
    },
    {
      name: name + 'AbolishCheckItem',
      title: '批量作废'
    },
    {
      name: name + 'Export',
      title: '批量导出'
    }
  ],
  pointBillsList: (name) => [
    {
      name: name + 'PassPointItem',
      title: '批量跳过'
    },
    {
      name: name + 'AbolishPointItem',
      title: '批量作废'
    },
    {
      name: name + 'Export',
      title: '批量导出'
    }
  ],
  maintenanceBillsList: (name) => [
    {
      name: name + 'PassMaintenanceItem',
      title: '批量跳过'
    },
    {
      name: name + 'AbolishMaintenanceItem',
      title: '批量作废'
    },
    {
      name: name + 'Export',
      title: '批量导出'
    }
  ],
  equipmentParams: (name) => [
    {
      name: name + 'ACallParamsList',
      title: '参数台账',
      children: [
        {
          name: name + 'CallParamsList',
          title: '列表'
        },
        {
          name: name + 'CallParamsAdd',
          title: '新增'
        },
        {
          name: name + 'CallParamsModify',
          title: '修改'
        },
        {
          name: name + 'CallParamsDel',
          title: '删除'
        },
        {
          name: name + 'CallParamsStatus',
          title: '启用/停用'
        }
      ]
    },
    {
      name: name + 'BDataOrigin',
      title: '数据源',
      children: [
        {
          name: name + 'DataOriginList',
          title: '列表'
        },
        {
          name: name + 'DataOriginAdd',
          title: '新增'
        },
        {
          name: name + 'DataOriginSetPoint',
          title: '设置数据点'
        },
        {
          name: name + 'DataOriginModify',
          title: '修改'
        },
        {
          name: name + 'DataOriginDel',
          title: '删除'
        },
        {
          name: name + 'DataOriginStatus',
          title: '启用/停用'
        }
      ]
    }
  ]
}
