import authDashBoardButtons from '@/router/page-buttons/auth-dashBoard.buttons'

export default {
  path: 'statistics-statement',
  name: 'statisticsStatement',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '统计报表', icon: require('@/assets/l_c_S_images/统计@2x.png'), activeMenu: 'statistics-statement' },
  redirect: '/statistics-statement/workshop-screen/prod-screen',
  children: [
    {
      path: 'workshop-screen',
      name: 'WorkshopScreen',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '车间大屏', noCache: true, activeMenu: 'workshop-screen' },
      redirect: '/statistics-statement/workshop-screen/prod-screen',
      children: [
        {
          path: 'prod-screen',
          name: 'ProdScreen',
          component: () => import('@/views/statistic-statement/workshop-screen/index.vue'),
          meta: { title: '生产大屏', noCache: true, activeMenu: 'prod-screen', canLink: true }
        }
      ]
    },
    {
      path: 'statistics-board',
      name: 'statisticsBoard',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '统计看板', activeMenu: 'statistics-board' },
      redirect: '/statistics-statement/statistics-board/statement-manage',
      children: [
        {
          path: 'statement-manage',
          name: 'StatementManage',
          component: () => import('@/views/statistic-statement/statement-manage/index.vue'),
          meta: { title: '报表管理', icon: require('@/assets/statistics-board/卡片模式_块@2x.png'), activeMenu: 'statement-manage' }
        }
      ]
    }

  ]
}
