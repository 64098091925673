  export default {
    ProjectManagementList: (name) => [
      {
        name: name + 'List',
        title: '列表'
      },
      {
        name: name + 'Add',
        title: '新增'
      },
      {
        name: name + 'Over',
        title: '结案'
      },
      {
        name: name + 'Restart',
        title: '重启'
      },
      {
        name: name + 'Copy',
        title: '复制'
      },
      {
        name: name + 'Modify',
        title: '修改'
      },
      {
        name: name + 'Del',
        title: '删除'
      },
      {
        name: name + 'Print',
        title: '打印'
      }
    ],
    ProjectSchemeList: (name) => [
      {
        name: name + 'List',
        title: '列表'
      },
      {
        name: name + 'Add',
        title: '新增'
      },
      {
        name: name + 'Modify',
        title: '修改'
      },
      {
        name: name + 'Del',
        title: '删除'
      },
      {
        name: name + 'Copy',
        title: '复制'
      },
      {
        name: name + 'Default',
        title: '默认'
      }
    ],
    PlanSchedules: (name) => [
      {
        name: name + 'List',
        title: '甘特图'
      },
      {
        name: name + 'Modify',
        title: '修改计划节点'
      }
    ],
    ProjectTaskList: (name) => [
      {
        name: name + 'List',
        title: '列表'
      },
      {
        name: name + 'Detail',
        title: '详情'
      },
      {
        name: name + 'Modify',
        title: '修改'
      },
      {
        name: name + 'Start',
        title: '开始任务'
      },
      {
        name: name + 'Finish',
        title: '完成任务'
      },
      {
        name: name + 'AddReport',
        title: '汇报'
      },
    ],
    ProjectTaskDetail: (name) => [
      {
        name: name + 'Start',
        title: '开始任务'
      },
      {
        name: name + 'Finish',
        title: '完成任务'
      },
      {
        name: name + 'AddReport',
        title: '汇报'
      },
      {
        name: name + 'ReportList',
        title: '汇报列表'
      },
      {
        name: name + 'ReportDel',
        title: '汇报删除'
      }
    ],
    TaskNodes: (name) => [
      {
        name: name + 'List',
        title: '列表'
      },
      {
        name: name + 'Add',
        title: '新增'
      },
      {
        name: name + 'Modify',
        title: '修改'
      },
      {
        name: name + 'Del',
        title: '删除'
      },
      {
        name: name + 'Copy',
        title: '复制'
      }
    ]
  }
