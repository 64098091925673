import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
export default {
  data() {
    return {
      fieldData: []
    }
  },
  methods: {
    // 自定义列表-未删除、已启用、表头标识
    async fieldList() {
      const search = [
        {
          id: getUUid(),
          fieldName: 'isDeleted',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        },
        {
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        },
        {
          id: getUUid(),
          fieldName: 'tableSign',
          fieldType: 'string',
          fieldValue: this.tableSign,
          operator: 'eq'
        }
      ]
      const res = await this.fieldApi.getFieldSetList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.searchFieldData = res.filter(item => item.search === 1)
        if (this.currentSign === 'add') {
          this.fieldForm = res
          if (this.fieldForm.length > 0) this.fixFieldData()
          return false
        }
        this.fieldData = res
        if (this.fieldData.length > 0) this.fixFieldTableData()
      }
    },
    // 处理表头自定义字段显示
    fixFieldTableData() {
      const list = this.fieldData.map(item => {
        return {
          prop: item.filedName,
          label: item.filedName,
          sortable: false,
          hiddenSearch: true
        }
      })
      this.columns = [...this.columns, ...list,
        ...this.actionColumn
      ]
    },
    // 新增-修改-处理自定义数据展示
    fixFieldData(val) {
      const list = this.fieldForm.map(item => {
        if (+val === 1) {
          return {
            key: item.fieldSet.filedName,
            label: item.fieldSet.filedName,
            required: item.fieldSet.required !== 0,
            defaultValue: item.fieldSet.filedType === 3 && item.value ? item.value.split(',') : item.value,
            filedProperty: item.fieldSet.filedProperty,
            filedType: item.fieldSet.filedType,
            ...this.paramsTypeList[item.fieldSet.filedType]
          }
        }
        return {
          key: item.filedName,
          label: item.filedName,
          required: item.required !== 0,
          defaultValue: item.defaultValue,
          filedProperty: item.filedProperty,
          filedType: item.filedType,
          ...this.paramsTypeList[item.filedType]
        }
      })
      const data = list.map(item => {
        let max
        let min
        if (item.filedType === 1) {
          const arr = item.filedProperty ? item.filedProperty.split(',') : []
          if (arr.length === 2) {
            max = +arr[1].split(':')[1]
            min = +arr[0].split(':')[1]
          } else {
            const num = arr.length === 1 ? arr[0].indexOf('最小值') > -1 : false
            const num1 = arr.length === 1 ? arr[0].indexOf('最大值') > -1 : false
            if (num) {
              min = +arr[0].split(':')[1]
              max = undefined
            }
            if (num1) {
              max = +arr[0].split(':')[1]
              min = undefined
            }
          }
          return {
            ...item,
            defaultValue: item.defaultValue || undefined,
            props: {
              controlsPosition: 'right',
              placeholder: '',
              min: min,
              max: max
            }
          }
        }
        if (item.filedType === 2 || item.filedType === 3) {
          return {
            ...item,
            children: [
              {
                tagName: 'el-option',
                props: {
                  label: 'name',
                  value: 'id'
                },
                options: item.filedProperty ? item.filedProperty.split(',').map(item => {
                  return {
                    id: item,
                    name: item
                  }
                }) : []
              }
            ]
          }
        }
        return {
          ...item
        }
      })
      this.fieldFormList = data
      this.$refs.fieldForm.formLists = data
      this.$refs.fieldForm.setSomeData()
    },
    // 处理传给后端的自定义字段
    fixSaveData(data) {
      if (this.params.type === 'add') {
        return this.fieldForm.map(item => {
          return {
            fieldSetId: item.id,
            value: item.filedType === 3 ? data[item.filedName] ? `,${data[item.filedName].join(',')},` : '' : data[item.filedName] || ''
          }
        })
      }
      return this.fieldForm.map(item => {
        return {
          fieldSetId: item.fieldSet.id,
          value: item.fieldSet.filedType === 3 ? data[item.fieldSetName] ? `,${data[item.fieldSetName].join(',')},` : '' : data[item.fieldSetName] || ''
        }
      })
    }
  }
}
