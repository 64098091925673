const batchNumber = function(name){
    return [
        {
          name: name + 'List',
          title: '列表'
        },
        {
          name: name + 'fastPage',
          title: '生成批次'
        },
        {
          name: name + 'historyPage',
          title: '历史批次'
        },
        {
          name: name + 'Del',
          title: '删除'
        }
    ]
}

const packageCode = function(name){
    return [
        {
            name: name + 'List',
            title: '列表'
          },
          {
            name: name + 'fastPage',
            title: '生成批次'
          },
          {
            name: name + 'historyPage',
            title: '历史批次'
        }
    ]
}

export default {
  path: 'barcode',
  name: 'Barcode',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '条码管理', icon: require('@/assets/l_c_S_images/模具管理.svg'), activeMenu: 'barcode' },
  redirect: '/barcode/barcode-management',
  children: [{
    path: 'barcode-management',
    name: 'BarcodeManagement',
    component: () => import('@/layout/blank.vue'),
    meta: { title: '条码管理', activeMenu: 'barcode-management' },
    redirect: '/barcode/barcode-management/batch-number',
    children: [
        {
            path: 'batch-number',
            name: 'batchNumber',
            component: () => import('@/views/information/print-label/batch-number/batch-number.vue'),
            meta: { title: '打印批次码', activeMenu: 'batch-number', buttons: batchNumber('batchNumber') }
        },
        {
            path: 'package-code',
            name: 'packageCode',
            component: () => import('@/views/information/print-label/package-code/package-code.vue'),
            meta: { title: '打印包装码', activeMenu: 'package-code', buttons: packageCode('packageCode') }
        }
    ]
  }]
}
