export default {
  planManage: (name) => [
    {
      name: name + 'KeyUse',
      title: '一键占用'
    },
    {
      name: name + 'StoreEdit',
      title: '已占用库存编辑'
    },
    {
      name: name + 'Requesting1',
      title: '按净需求请购'
    },
    {
      name: name + 'Requesting2',
      title: '按净截止需求请购'
    },
    {
      name: name + 'Requesting3',
      title: '请购'
    }
  ],
  materialPurchase: name => [
    {
      name: name + 'Requesting',
      title: '请购'
    }
  ],
  purchaseList: name => [
    {
      name: name + 'ExportPurchaseList',
      title: '导出请购物料清单'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Detail',
      title: '详情'
    }
  ],
  purchaseException: name => [
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  productionDemand: name => [
    {
      name: name + 'Scheduling',
      title: '自动排程'
    },
    {
      name: name + 'CreateTask',
      title: '创建生产任务'
    },
    {
      name: name + 'WaitDemand',
      title: '待定需求'
    }
  ],
  productionTask: name => [
    {
      name: name + 'View',
      title: '列表'
    }, {
      name: name + 'Edit',
      title: '编辑'
    }, {
      name: name + 'Assign',
      title: '指派任务'
    }, {
      name: name + 'Restart',
      title: '重启'
    }, {
      name: name + 'Del',
      title: '删除'
    }
  ],

  productionDemandList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Match',
      title: '模组匹配'
    },
    {
      name: name + 'Create',
      title: '创建工单'
    }
  ],
}
