<template>
  <div :id="commonIds" style="display: none;width: 100%">
    <div v-for="(item, index) in codes" :key="index">
      <div style="display: inline-flex;padding: 10px;border: 1px solid #000;border-radius: 4px;width: calc(100% - 20px)">
        <div v-if="item" class="left" style="border: 1px solid #000;width: 70%">
          <div style="width: 100%;display: flex;border-bottom: 1px solid #000;">
            <div style="width: 50%;display: inline-flex;border-right: 1px solid #000;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;">单号</div>
              <div style="font-size: 12px;padding: 2px 5px;width:calc(100%-30px);">{{ item.produceOrderNumber }}</div>
            </div>
            <div style="width: 50%;display: inline-flex;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;">等级</div>
              <div style="font-size: 12px;padding: 2px 5px;width:calc(100%-30px);">{{ priorityStatus[item.priority].name }}</div>
            </div>
          </div>
          <div style="width: 100%;display: flex;border-bottom: 1px solid #000;">
            <div style="width: 50%;display: inline-flex;border-right: 1px solid #000;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;">交期</div>
              <div style="font-size: 12px;padding: 2px 5px;width:calc(100%-30px);">{{ item.deliveryTime }}</div>
            </div>
            <div style="width: 50%;display: inline-flex;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;">页码</div>
              <div style="font-size: 12px;padding: 2px 5px;width:calc(100%-30px);">{{ item.id }}</div>
            </div>
          </div>
          <div style="width: 100%;display: flex;border-bottom: 1px solid #000;">
            <div style="width: 50%;display: inline-flex;border-right: 1px solid #000;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;">名称</div>
              <div style="font-size: 12px;padding: 2px 5px;width:calc(100%-30px);">{{ item.materialsName }}</div>
            </div>
            <div style="width: 50%;display: inline-flex;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;">数量</div>
              <div style="font-size: 12px;padding: 2px 5px;width:calc(100%-30px);">{{ item.quantity }}</div>
            </div>
          </div>
          <div style="width: 100%;display:inline-flex;;border-bottom: 1px solid #000;minHeight: 70px;">
            <div style="display: in;line-flex;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;line-height: 70px;">规格</div>
            </div>
            <div style="display: inline-flex;">
              <div style="font-size: 12px;padding: 2px 5px;width:100%;display:flex;align-items: center">{{ item.materialsSpecifications }}</div>
            </div>
          </div>
          <div style="width: 100%;display: inline-flex;;minHeight: 80px;">
            <div style="display: inline-flex;">
              <div style="font-size: 14px;padding: 2px 5px;width:30px;border-right: 1px solid #000;line-height: 80px;">备注</div>
            </div>
            <div style="display: inline-flex;">
              <div style="font-size: 12px;padding: 2px 5px;width:100%;display:flex;align-items: center">{{ item.remark }}</div>
            </div>
          </div>
          <!-- <div style="width: 10%;border-right: 1px solid #000;height: 100%">
            <div style="border-bottom: 1px solid #000;width: 100%;">
              <div style="font-size: 14px;margin: 2px 5px">单号</div>
            </div>
            <div style="border-bottom: 1px solid #000;width: 100%;">
              <div style="font-size: 14px;margin: 2px 5px">交期</div>
            </div>
           <div style="border-bottom: 1px solid #000;width: 100%;">
              <div style="font-size: 14px;margin: 2px 5px">名称</div>
            </div>
            <div style="border-bottom: 1px solid #000;width: 100%;minHeight: 60px;">
              <div style="font-size: 14px;line-height: 60px;margin: 2px 5px">规格</div>
            </div>
            <div style="width: 100%;minHeight: 80px;">
              <div style="font-size: 14px;line-height: 60px;margin: 2px 5px">备注</div>
            </div>
          </div>
          <div style="width: 90%">
            <div style="display: inline-flex;width: 100%">
              <div style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 50%;">
                <div style="font-size: 14px;margin: 2px 5px">{{ item.produceOrderNumber }}</div>
              </div>
              <div style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 10%;">
                <div style="font-size: 14px;margin: 2px 5px">等级</div>
              </div>
              <div style="border-bottom: 1px solid #000;width: 40%;">
                <div style="font-size: 14px;margin: 2px 5px">{{priorityStatus[item.priority].name}}</div>
              </div>
            </div>
            <div style="display: inline-flex;width: 100%">
              <div style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 50%;">
                <div style="font-size: 14px;margin: 2px 5px">{{ item.deliveryTime }}</div>
              </div>
              <div style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 10%;">
                <div style="font-size: 14px;margin: 2px 5px">页码</div>
              </div>
              <div style="border-bottom: 1px solid #000;width: 40%;">
                <div style="font-size: 14px;margin: 2px 5px">{{ item.id }}</div>
              </div>
            </div>
            <div style="display: inline-flex;width: 100%">
              <div style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 50%;">
                <div style="font-size: 14px;margin: 2px 5px">{{ item.materialsName}}</div>
              </div>
              <div style="border-bottom: 1px solid #000;border-right: 1px solid #000;width: 10%;">
                <div style="font-size: 14px;margin: 2px 5px">数量</div>
              </div>
              <div style="border-bottom: 1px solid #000;width: 40%;">
                <div style="font-size: 14px;margin: 2px 5px">{{item.quantity}}</div>
              </div>
            </div>
            <div style="display: inline-flex;width: 100%;align-items: center;border-bottom: 1px solid #000;height:62px;">
              <div style="font-size: 14px;margin: 2px 5px"> {{ item.materialsSpecifications }}</div>
            </div>
            <div style="display: inline-flex;width: 100%;align-items: center;height:80px;">
              <div style="font-size: 14px;margin: 2px 5px"> {{ item.remark }}</div>

            </div>
          </div> -->
        </div>
        <div class="right" style="text-align: center;width: 30%">
          <div style="position: relative;left: 50%;transform: translateX(-50%)">
            <div><img :src="companyInfo.logo" :alt="companyInfo.name" style="width: 95%"></div>
            <div v-if="item" style="position: relative;top: 10px">
              <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.batchNumber" :size="100" :margin="0" />
              <div>
                <div style="word-break: break-word">{{ item.batchNumber }}</div>
                <!-- <div>(报工请扫码)</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: 'PrintBatchNumber',
  components: { VueQr },
  props: {
    commonId: {
      type: String,
      default: 'print'
    }
  },
  data() {
    return {
      commonIds: 'print',
      codes: []
    }
  },
  computed: {
    companyInfo() {
      return this.$store.state.user.companyInfo
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  watch: {
    commonId(val) {
      this.commonIds = val
    }
  },
  methods: {
    print(code) {
      this.codes = [code]
      this.prints()
    },
    batchPrint(codes) {
      this.codes = codes
      this.prints()
    },
    prints() {
      if (this.companyInfo.logo.indexOf('http') === -1) {
        return this.printIframe()
      }
      const image = new Image()
      image.src = this.companyInfo.logo
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = () => {
        this.companyInfo.logo = this.getBase64Image(image)
        this.printIframe()
      }
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonIds}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.8'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'rgba(255, 255, 255, 0)'
      ctx.drawImage(img, 0, 0, img.width, img.height)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (imageData.data[i + 3] === 0) {
          imageData.data[i] = 255
          imageData.data[i + 1] = 255
          imageData.data[i + 2] = 255
          imageData.data[i + 3] = 255
        }
      }
      ctx.putImageData(imageData, 0, 0)
      return canvas.toDataURL('image/jpeg')
    }
  }
}
</script>

<style scoped>
  #pageFooter:after {
    counter-increment: page;
    content:"Page " counter(page);
    left: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 20;
    -moz-border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  }
</style>
