export default {
  waitingBoard: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ]
}
