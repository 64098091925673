var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "none", width: "100%" },
      attrs: { id: _vm.commonIds }
    },
    _vm._l(_vm.codes, function(item, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "inline-flex",
              padding: "10px",
              border: "1px solid #000",
              "border-radius": "4px",
              width: "calc(100% - 20px)"
            }
          },
          [
            item
              ? _c(
                  "div",
                  {
                    staticClass: "left",
                    staticStyle: { border: "1px solid #000", width: "70%" }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex", width: "100%" } },
                      [
                        _vm._m(0, true),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "border-bottom": "1px solid #000",
                              width: "60%",
                              padding: "2px 5px"
                            }
                          },
                          [
                            _c("VueQr", {
                              attrs: {
                                "correct-level": 3,
                                "auto-color": false,
                                "color-dark": "#000000",
                                text: item.produceWorkOrderNumber,
                                size: 110,
                                margin: 0
                              }
                            }),
                            _c(
                              "div",
                              { staticStyle: { "font-size": "12px" } },
                              [_vm._v(_vm._s(item.produceitemNumber))]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "flex", width: "100%" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "line-height": "30px",
                              "border-bottom": "1px solid #000",
                              "border-right": "1px solid #000",
                              width: "40%",
                              padding: "2px 5px"
                            }
                          },
                          [_vm._v("生产订单号")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "line-height": "30px",
                              "border-bottom": "1px solid #000",
                              width: "60%",
                              padding: "2px 5px"
                            }
                          },
                          [_vm._v(_vm._s(item.produceOrderNumber))]
                        )
                      ]
                    ),
                    _vm._m(1, true),
                    _c(
                      "div",
                      { staticStyle: { display: "flex", width: "100%" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "line-height": "30px",
                              "border-bottom": "1px solid #000",
                              "border-right": "1px solid #000",
                              width: "40%",
                              padding: "2px 5px"
                            }
                          },
                          [_vm._v("生产日期")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "line-height": "30px",
                              "border-bottom": "1px solid #000",
                              width: "60%",
                              padding: "2px 5px"
                            }
                          },
                          [_vm._v(_vm._s(_vm.currentDate))]
                        )
                      ]
                    ),
                    _vm._m(2, true),
                    _vm._m(3, true),
                    _vm._m(4, true),
                    _vm._m(5, true)
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "right",
                staticStyle: { "text-align": "center", width: "30%" }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "relative",
                      left: "50%",
                      transform: "translateX(-50%)"
                    }
                  },
                  [
                    _c("div", [
                      _c("img", {
                        staticStyle: { width: "95%" },
                        attrs: {
                          src: _vm.companyInfo.logo,
                          alt: _vm.companyInfo.name
                        }
                      })
                    ]),
                    item
                      ? _c(
                          "div",
                          {
                            staticStyle: { position: "relative", top: "50px" }
                          },
                          [
                            _c("VueQr", {
                              attrs: {
                                "correct-level": 3,
                                "auto-color": false,
                                "color-dark": "#000000",
                                text: item.batchNumber,
                                size: 150,
                                margin: 0
                              }
                            }),
                            _c("div", [
                              _c(
                                "div",
                                { staticStyle: { "word-break": "break-word" } },
                                [_vm._v(_vm._s(item.batchNumber))]
                              ),
                              _c("div", [_vm._v("(报工请扫码)")])
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "border-bottom": "1px solid #000",
          "border-right": "1px solid #000",
          width: "40%",
          padding: "2px 5px"
        }
      },
      [
        _c("div", { staticStyle: { "font-size": "14px" } }, [
          _vm._v("生产工单号")
        ]),
        _c("div", { staticStyle: { "font-size": "14px" } }, [
          _vm._v("(扫码查工单)")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "line-height": "30px",
            "border-bottom": "1px solid #000",
            "border-right": "1px solid #000",
            width: "40%",
            padding: "2px 5px"
          }
        },
        [_vm._v("生产设备")]
      ),
      _c("div", {
        staticStyle: {
          "font-size": "14px",
          "line-height": "30px",
          "border-bottom": "1px solid #000",
          width: "60%",
          padding: "2px 5px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "line-height": "30px",
            "border-bottom": "1px solid #000",
            "border-right": "1px solid #000",
            width: "40%",
            padding: "2px 5px"
          }
        },
        [_vm._v("员工工号")]
      ),
      _c("div", {
        staticStyle: {
          "font-size": "14px",
          "line-height": "30px",
          "border-bottom": "1px solid #000",
          width: "60%",
          padding: "2px 5px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "line-height": "30px",
            "border-bottom": "1px solid #000",
            "border-right": "1px solid #000",
            width: "40%",
            padding: "2px 5px"
          }
        },
        [_vm._v("检验日期")]
      ),
      _c("div", {
        staticStyle: {
          "font-size": "14px",
          "line-height": "30px",
          "border-bottom": "1px solid #000",
          width: "60%",
          padding: "2px 5px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "line-height": "30px",
            "border-bottom": "1px solid #000",
            "border-right": "1px solid #000",
            width: "40%",
            padding: "2px 5px"
          }
        },
        [_vm._v("检验结果")]
      ),
      _c("div", {
        staticStyle: {
          "font-size": "14px",
          "line-height": "30px",
          "border-bottom": "1px solid #000",
          width: "60%",
          padding: "2px 5px"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "line-height": "30px",
            "border-right": "1px solid #000",
            width: "40%",
            padding: "2px 5px"
          }
        },
        [_vm._v("生产许可证")]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "line-height": "30px",
            width: "60%",
            padding: "2px 5px"
          }
        },
        [_vm._v("沪XK16-204-01164")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }