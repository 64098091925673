import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'

export default {
  data() {
    return {
      defaultSearch: [{
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }],
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      searchFormatData: [],
      batchParam: {},
      materialsId:null
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    }
  },
  methods: {
    // 批量删除
    batchDel() {
      this.del({ id: this.exportSelectionIds })
    },

    // 批量导出
    batchExport() {
      this.$refs.functionHeader.export({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.exportSelectionIds.join('^'),
          operator: 'in'
        }]))
      })
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const params = this.getTableDataParams || {}
      if (this.searchKeyword) {
        params.searchKeyword = this.functionHeader.searchVal
      } else if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: this.searchFieldName || 'name',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      const extraData = this.extraParams || []
      // console.log(params,'搜索=》', extraData, search, this.searchFormatData)
      const searchParams = {
        sort: order,
        ...params,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData, ...extraData]))
      }
      if (this.functionHeader && this.functionHeader.exportParams) this.functionHeader.exportParams = searchParams
      const res = await this.api[`get${this.params.radio}ListPage`]( {
        page: page.currentPage,
        limit: page.pageSize,
        materialsId:this.materialsId>=0?this.materialsId:null,
        ...searchParams
      })
      if (res) {
        // 针对备件台账 备件图片处理 后端返回[] 需要变成 "" ————————Start————————
        if(this.params.radio === "SparePart"){
          res.records.forEach(p => p.drawing === "[]" ? p.drawing = "" : "");
        }
        // 针对备件台账 备件图片处理 后端返回[] 需要变成 "" ————————End————————
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr)
      } catch (err) {
        return []
      }
    },
    add() {
      if(this.params.radio === "CallReason"){
        this.formList[this.params.radio][2].props.disabled = false
      }
      if(this.params.radio === "WorkhoursReason"){
        this.formList[0].props.disabled = false
      }
      this.formData = {}
      this.currentType = 'add'
      this.visible = true
    },
    modifyOrCopy(row, command) {
      if(this.params.radio === "CallReason"){
        this.formList[this.params.radio][2].props.disabled = true
      }
      if(this.params.radio === "WorkhoursReason"){
        this.formList[0].props.disabled = true
      }
      this.formData = row
      this.continuousAdd = false
      this.currentType = command
      this.visible = true
    },
    // 更改物料状态
    async switchStatus(row, path) {
      const res = await this.api.switchStatus(row.isEnabled ? 'disable' : 'enable', path.replace(path[0], path[0].toLowerCase()), row.id)
      if (res) {
        this.$message.success(`${row.name || row.reason}${row.isEnabled ? '停用' : '启用'}成功`)
        this.$refs.mTable.setTableData()
      }
    },
    async del({ id }, callback) {
      const params = {}
      let api
      if (Array.isArray(id)) {
        params.ids = id
        api = this.api.batchDelete
      } else {
        params.id = id
        api = this.api[`del${this.params.radio}`]
      }
      const that = this
      if (callback) {
        const res = await api(params)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
          if (this.getDeviceParametersRecord) this.getDeviceParametersRecord()
        }
      } else {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api(params)
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.tableRefs().clearSelection()
            this.$refs.mTable.setTableData()
            if (that.getDeviceParametersRecord) that.getDeviceParametersRecord()
          if(this.getDeviceParametersRecordTrend)this.getDeviceParametersRecordTrend()

          }
        })
      }
    },
    submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await this.api[`${this.currentType}${this.params.radio}`](
            { ...(this.currentType !== 'modify' ? this.createSetData(form) : this.createResetData(form)),
              processFiles: this.$refs?.ProcessFile?.baseList }
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            if (this.continuousAdd) {
              this.formData = {}
            } else {
              this.visible = false
            }
            if(this.cardPicture){
              this.cardPicture = ''
            }
            this.$refs.mTable.setTableData()
            if (this.getDeviceParametersRecord) this.getDeviceParametersRecord()
          if(this.getDeviceParametersRecordTrend)this.getDeviceParametersRecordTrend()

          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    getSuccessMessage() {
      return `${this.messages[this.currentType]}成功`
    }
  }
}
