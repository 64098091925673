const maintenanceCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  }
]
export default {
  facilityAccountList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'AddFacilityInfo',
      title: '添加质保信息'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  ToolLedger: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Edit',
      title: '编辑'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  facilityAccountDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    }
  ],
  technicalParameterList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  facilityMaintenanceList: (name) => [
    {
      name: name + 'AFacilityMaintenancePlanList',
      title: '保养计划',
      children: [
        ...maintenanceCommon(name, 'FacilityMaintenancePlan'),
        {
          name: name + 'Copy',
          title: '复制'
        }
      ]
    },
    {
      name: name + 'BPlanMaintenanceTemplateList',
      title: '保养计划模板',
      children: [
        ...maintenanceCommon(name, 'PlanMaintenanceTemplate'),
        {
          name: name + 'User',
          title: '使用'
        }
      ]
    }
  ],
  facilityPointList: (name) => [
    {
      name: name + 'AFacilitySpotCheckPlan',
      title: '点检计划',
      children: [
        ...maintenanceCommon(name, 'FacilitySpotCheckPlan'),
        {
          name: name + 'Copy',
          title: '复制'
        }
      ]
    },
    {
      name: name + 'BPlanSpotCheckTemplateList',
      title: '点检计划模板',
      children: [
        ...maintenanceCommon(name, 'PlanSpotCheckTemplate'),
        {
          name: name + 'User',
          title: '使用'
        }
      ]
    }
  ],
  configurationParameterList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  factoryParameterList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  auxiliaryMaterialsList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  auxiliaryMaterialsDetail: (name) => [
    {
      name: name + 'List',
      title: '列表'
    }
  ]
}
