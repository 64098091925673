import mouldButtons from './page-buttons/mould.buttons'
export default {
  path: 'mould',
  name: 'Mould',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '模具管理', icon: require('@/assets/l_c_S_images/模具管理.svg'), activeMenu: 'mould' },
  redirect: '/mould/mould-management',
  children: [{
    path: 'mould-management',
    name: 'MouldManagement',
    component: () => import('@/layout/blank.vue'),
    meta: { title: '模具管理', activeMenu: 'mould-management' },
    redirect: '/mould/mould-management/mould-define',
    children: [
      {
        path: 'mould-define',
        name: 'MouldDefine',
        component: () => import('@/views/mould-management/mouldDefine.vue'),
        meta: { title: '模具定义', activeMenu: 'mould-define', buttons: mouldButtons.mouldDefine('MouldDefine'), noCache: true }
      },
      {
        path: 'mould-handle',
        name: 'MouldHandle',
        component: () => import('@/views/mould-management/mouldHandle'),
        meta: { noCache: true, title: '模具操作', activeMenu: 'mould-handle' },
        hidden: true
      },
      {
        path: 'mould-stock',
        name: 'MouldStock',
        component: () => import('@/views/mould-management/mouldStock.vue'),
        meta: { title: '模具库存', activeMenu: 'mould-stock', buttons: mouldButtons.mouldStock('MouldStock'), noCache: true }
      },
      {
        path: 'mould-inStock',
        name: 'InStock',
        component: () => import('@/views/mould-management/inStock'),
        meta: { noCache: true, title: '模具入库', activeMenu: 'mould-inStock' },
        hidden: true
      }
    ]
  }]
}
