<template>
  <div :id="commonIds" style="display: none;width: 100%">
    <div v-for="(item, index) in codes" :key="index" style="padding-top: 5px;display:inline-flex;margin: 0 8px;justify-content:flex-start;align-items: center">
      <div style="width:50%;height:calc(100vh - 30px);border: 2px solid #000;border-radius: 5px;margin:0 20px 0 0px;padding: 5px 10px;">
        <div style="width: 100%;font-size: 18px;margin-bottom: 8px;text-align: left">
          <span style="width: 90px;">生产设备：</span>
          <span style="font-size: 12px" />
        </div>
        <div style="width: 100%;font-size: 18px;margin-bottom: 8px;text-align: left">
          <span style="width: 90px;">生产日期：</span>
          <span style="font-size: 12px">{{ times }}</span>
        </div>
        <div style="font-size: 18px;margin-bottom: 8px;text-align: left">
          <span style="width: 90px;">员工工号：</span>
          <span style="font-size: 12px" />
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: flex-start;margin-bottom: 9px">
          <div style="text-align: left;flex: 1;">
            <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.batchNumber" :size="40" :margin="0" />
          </div>
          <div style="text-align: left;flex:2">
            <VueBarcode
              :value="item.batchNumber"
              :width="0.8"
              :text="''"
              :height="30"
              :font-size="12"
              :margin="0"
              :font="'PingFangSC-Regular'"
              :text-align="'left'"
            />
          </div>
        </div>
      </div>
      <div style="width:50%;height:calc(100vh - 30px);border: 2px solid #000;border-radius: 5px;padding: 5px 10px;margin:0 0px 0 20px;">
        <div style="font-size: 18px;margin-bottom: 8px;text-align: left">
          <span style="width: 90px;">生产设备：</span>
          <span style="font-size: 12px" />
        </div>
        <div style="font-size: 18px;margin-bottom: 8px;text-align: left">
          <span style="width: 90px;">生产日期：</span>
          <span style="font-size: 12px">{{ times }}</span>
        </div>
        <div style="font-size: 18px;margin-bottom: 8px;text-align: left">
          <span style="width: 90px;">员工工号：</span>
          <span style="font-size: 12px" />
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: flex-start;margin-bottom: 9px;">
          <div style="text-align: left;flex: 1;">
            <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.batchNumber" :size="40" :margin="0" />
          </div>
          <div style="text-align: left;flex:2">
            <VueBarcode
              :value="item.batchNumber"
              :width="0.8"
              :text="''"
              :height="30"
              :font-size="12"
              :margin="0"
              :font="'PingFangSC-Regular'"
              :text-align="'left'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import JsBarcode from 'jsbarcode'
import VueQr from 'vue-qr'
import VueBarcode from 'vue-barcode'
export default {
  name: 'PrintCode',
  components: { VueBarcode, VueQr },
  props: {
    commonId: {
      type: String,
      default: 'print'
    }
  },
  data() {
    return {
      codes: [],
      commonIds: 'print',
      times: dayjs().format('YYYY-MM-DD')
    }
  },
  watch: {
    commonId(val) {
      this.commonIds = val
    }
  },
  methods: {
    fixData(val) {
      if (val) {
        return val.split('^').join('；')
      }
    },
    print(code) {
      this.codes = [code]
      this.printIframe()
    },
    batchPrint(codes) {
      this.codes = codes
      this.printIframe()
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonIds}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;"align="center">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.8'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    }
  }
}
</script>

<style scoped>

</style>
