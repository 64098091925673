import changeButtons from './page-buttons/changeManagement.buttons'
export default {
  path: 'change-management',
  name: 'ChangeManagement',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '变更管理', icon: require('@/assets/l_c_S_images/变更管理.svg'), activeMenu: 'change-management' },
  redirect: '/change-management/file',
  children: [
    {
      path: 'file',
      name: 'File',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '变更管理', activeMenu: 'file' },
      redirect: '/change-management/file',
      children: [
        {
          path: 'change-info',
          name: 'ChangeManagementInfo',
          component: () => import('@/layout/blank.vue'),
          redirect: '/change-management/file/change-info/list',
          meta: { noCache: true, title: '工程变更单', activeMenu: 'change-info', canLink: true },
          children: [
            {
              path: 'list',
              name: 'ChangeManagementList',
              component: () => import('@/views/changeManage/list.vue'),
              meta: { noCache: false, title: '变更列表', activeMenu: 'change-info', buttons: changeButtons.ChangeManagementList('ChangeManagementList')},
              hidden: true
            },
            {
              path: 'add',
              name: 'AddChangeOrder',
              component: () => import('@/views/changeManage/detail/add.vue'),
              meta: { title: '新增变更单', noCache: true, activeMenu: 'change-info', nb: true },
              hidden: true
          },
            {
                path: 'detail',
                name: 'ChangeManagementDetail',
                component: () => import('@/views/changeManage/detail/detail.vue'),
                meta: { title: '变更详情', noCache: true, activeMenu: 'change-info', nb: true },
                hidden: true
            }
          ]
        }
      ]
    }
  ]
}
