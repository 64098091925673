import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  data() {
    return {
      exportSelectionIds: [],
      exportSelectionList:[]
    }
  },
  methods: {
    selectionChange(list) {
      this.exportSelectionIds = list.map(item => item.id)
      this.exportSelectionList = list
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExportFront() },
        batchDel: () => { this.batchDel() }
      }
      functions[command]()
    },
    batchExportFront() {
      this.$refs.functionHeader.export({
        searchVal: Encrypt(JSON.stringify([{
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.exportSelectionIds.join('^'),
          operator: 'in'
        }]))
      })
    }
  }
}
