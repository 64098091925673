import qualityManage from './page-buttons/qualityManage.buttons'
export default {
  path: 'quality-management',
  name: 'QualityManagement',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '质量管理', icon: require('@/assets/l_c_S_images/质量.png'), activeMenu: 'quality-management' },
  redirect: '/quality-management/file',
  children: [
    {
      path: 'file',
      name: 'File',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '质检管理', activeMenu: 'file' },
      redirect: '/quality-management/file',
      children: [
        // {
        //   path: 'waiting-board',
        //   name: 'WaitingBoard',
        //   component: () => import('@/views/qualityManagement/waitingBoard.vue'),
        //   meta: { noCache: true, title: '待检看板', activeMenu: 'waiting-board'}
        // },
        {
          path: 'quality-task',
          name: 'QualityTask',
          component: () => import('@/layout/blank.vue'),
          redirect: '/quality-management/file/quality-task/quality-task-list',
          meta: { noCache: true, title: '质检任务', activeMenu: 'quality-task', canLink: true },
          children: [
            {
              path: 'quality-task-list',
              name: 'QualityTaskList',
              component: () => import('@/views/qualityManagement/quality-task/list.vue'),
              meta: { noCache: true, title: '任务列表', activeMenu: 'quality-task'},
              hidden: true
            },
            {
                path: 'detail',
                name: 'QualityTaskDetail',
                component: () => import('@/views/qualityManagement/quality-task/detail.vue'),
                meta: { title: '详情', noCache: false, activeMenu: 'quality-task', nb: true },
                hidden: true
            }
          ]
        }
      ]
    },
    {
      path: 'quality-plan-info',
      name: 'qualityPlanInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '质检方案配置', activeMenu: 'quality-plan-info' },
      redirect: '/quality-management/quality-plan-info/quality-project',
      children: [
        {
          path: 'quality-project',
          name: 'InspectionProject',
          component: () => import('@/views/qualityManagement/quality-project/list.vue'),
          meta: { title: '质检项目', icon: 'items', activeMenu: 'inspection-project'}
        },
        {
          path: 'quality-plan',
          name: 'QualityPlan',
          component: () => import('@/layout/blank.vue'),
          redirect: '/quality-management/quality-plan-info/quality-plan/quality-Planlist',
          meta: { noCache: true, title: '质检方案', activeMenu: 'quality-plan', canLink: true },
          children: [
            {
              path: 'quality-Planlist',
              name: 'QualityPlanList',
              component: () => import('@/views/quality/inspection-plan/inspection-plan.vue'),
              meta: { noCache: true, title: '方案列表', activeMenu: 'quality-plan'},
              hidden: true
            },
            {
                path: 'detail',
                name: 'QualityPlanDetail',
                component: () => import('@/views/quality/inspection-plan/detail/detail.vue'),
                meta: { title: '详情', noCache: false, activeMenu: 'quality-plan', nb: true },
                hidden: true
            }
          ]
        }
      ]
    }
  ]
}
