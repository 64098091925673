<template>
  <div :id="commonIds" style="display: none">
    <div v-for="(item, index) in codes" :key="index" style="padding-top: 10px;">
      <div style="display:inline-flex;justify-content:flex-start;">
        <div style="width:120px;">
          <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.batchNumber" :size="140" :margin="0" />  
        </div>
        <div style="margin-left: 26px;text-align: left">
          <div style="font-size: 18px;">物料名称：{{item.materialsName}}</div>
          <div style="font-size: 18px;">物料编码：{{item.materialsCode}}</div>
          <div style="font-size: 18px;">物料规格：{{item.materialsSpecifications}}</div>
          <div style="font-size: 18px;">生产日期：{{times}}</div>
          <div style="font-size: 18px;">批次码：{{ item.batchNumber}}</div>
          <div v-if="item.memberCode === 'bbelc'" style="font-size: 18px;">工厂名称：上海工厂</div>
          <div v-if="item.memberCode === 'bbwz'" style="font-size: 18px;">工厂名称：温州工厂</div>
        </div>
      </div>
      <div style="font-size: 15px;">
        <span v-for="row in item.reportDatas" :key="row.name" style="font-size: 15px;text-align:left;">
          <!-- {{ row.name }}： -->
          <span v-for="(i, idx) in row.fromData" :key="i.id">
            <span class="bot-ti">{{ i.key }}：{{ fixData(i.value) }}</span>
            <span v-if="idx === 3"><br></span>
            <span v-if="idx < row.fromData.length-2">；</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import JsBarcode from 'jsbarcode'
import VueQr from 'vue-qr'
export default {
  name: 'PrintCode',
  components: { VueQr },
  props: {
    commonId: {
      type: String,
      default: 'print'
    }
  },
  data() {
    return {
      codes: [],
      commonIds: 'print',
      times: dayjs().format('YYYY-MM-DD')
    }
  },
  watch: {
    commonId(val) {
      this.commonIds = val
    }
  },
  methods: {
    fixData(val) {
      if (val) {
        return val.split('^').join('；')
      }
    },
    print(code) {
      this.codes = [code]
      this.printIframe()
    },
    batchPrint(codes) {
      this.codes = codes
      this.printIframe()
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonIds}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;"align="center">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.8'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    }
  }
}
</script>

<style scoped></style>
