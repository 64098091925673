export default {
  applyMarket: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ]),
  applyDetails: (name) => ([
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ])
}
