
export default {
  WarehousesMaterials: name => [
    {
      name: name + 'MaterialsList',
      title: '列表'
    },
    {
      name: name + 'MaterialsExport',
      title: '导出'
    }
  ],
  WarehousesPackaging: name => [
    {
      name: name + 'PackageList',
      title: '列表'
    },
    {
      name: name + 'PackageExport',
      title: '导出'
    }
  ],
  WarehousesTray: name => [
    {
      name: name + 'TrayList',
      title: '列表'
    },
    {
      name: name + 'TrayExport',
      title: '导出'
    }
  ],
  warehouses: (name = 'warehouses') => {
    const defaultData = [
      {
        name: name + 'AMaterialsList',
        title: '物料',
        children: [
          {
            name: name + 'MaterialsList',
            title: '列表'
          },
          {
            name: name + 'MaterialsExport',
            title: '导出'
          }
        ]
      },
      {
        name: name + 'BPackageList',
        title: '包装',
        children: [
          {
            name: name + 'PackageList',
            title: '列表'
          },
          {
            name: name + 'PackageExport',
            title: '导出'
          }
        ]
      },
      {
        name: name + 'CTrayList',
        title: '托盘',
        children: [
          {
            name: name + 'TrayList',
            title: '列表'
          },
          {
            name: name + 'TrayExport',
            title: '导出'
          }
        ]
      }
    ]
    if (sessionStorage.getItem('memberCode') === 'bbelc') {
      defaultData.forEach(item => item.children.push({
        name: name + 'BatchEnterWarehouse',
        title: '批量入库'
      }))
    }
    return defaultData
  },
  sitWarehouseAccount: (name) => [
    {
      name: name + 'MaterialsList',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'BatchExport',
      title: '批量导出'
    }
  ]
}
