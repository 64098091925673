import serviceButtons from './page-buttons/xiewei/service.button'
import facilityButtons from './page-buttons/xiewei/facility.buttons'
import informationButtons from './page-buttons/information.buttons'

export default {
  path: 'service',
  name: 'service',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '售后服务', icon: require('@/assets/l_c_S_images/售后服务.svg'), activeMenu: 'service' },
  redirect: '/service/service-management/maintain',
  children: [
    {
      path: 'service-management',
      name: 'serviceManagement',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '服务管理', activeMenu: 'service-management' },
      redirect: '/service/service-management/demand-center',
      children: [
        {
          path: 'demand-center',
          name: 'demandCenter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '需求中心', activeMenu: 'demand-center', canLink: true },
          redirect: '/service/service-management/demand-center/list',
          children: [
            {
              path: 'list',
              name: 'demandCenterList',
              component: () => import('@/views/service/demand-center/list.vue'),
              meta: { roleTitle: '列表', title: '需求中心', noCache: false, activeMenu: 'demand-center', buttons: serviceButtons.DemandCenterList('demandCenterList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addDemandCenter',
              component: () => import('@/views/service/demand-center/add-demand-center'),
              meta: { title: '新增需求', activeMenu: 'demand-center', noCache: true, nb: true },
              hidden: true
            },
            {
              path: 'detail',
              name: 'demandCenterDetail',
              component: () => import('@/views/service/demand-center/detail/detail'),
              meta: { title: '需求详情', activeMenu: 'demand-center', noCache: true, nb: true },
              hidden: true
            },
            {
              path: 'add-travel-plan',
              name: 'addTravelPlan',
              component: () => import('@/views/service/demand-center/add-travel-plan/add-travel-plan'),
              meta: { roleTitle: '出差计划', title: '出差计划', noCache: true, activeMenu: 'demand-center', nb: true },
              hidden: true
            },
            {
              path: 'add-local-development',
              name: 'addLocalDevelopment',
              component: () => import('@/views/service/demand-center/add-local-development/add-local-development'),
              meta: { roleTitle: '本地开发申请表', title: '本地开发申请表', noCache: true, activeMenu: 'demand-center', nb: true },
              hidden: true
            }

          ]
        },
        {
          path: 'maintain',
          name: 'maintain',
          component: () => import('@/views/service/maintain/maintain'),
          meta: { noCache: false, title: '保养工单', activeMenu: 'maintain', buttons: serviceButtons.MaintenancePlan('maintain') }
        },
        {
          path: 'maintain-detail',
          name: 'maintainDetail',
          component: () => import('@/views/service/maintain-detail/maintain-detail'),
          meta: { noCache: true, title: '工单详情', activeMenu: 'maintain', nb: true },
          hidden: true
        },
        {
          path: 'spot-check',
          name: 'spotCheck',
          component: () => import('@/views/service/spot-check/spot-check'),
          meta: { noCache: false, title: '点检工单', activeMenu: 'spot-check', buttons: serviceButtons.SpotCheckPlan('spotCheck') }
        },
        {
          path: 'spot-check-add',
          name: 'addSpotCheck',
          component: () => import('@/views/service/spot-check-add/spot-check-add'),
          meta: { noCache: true, title: '新增点检工单', activeMenu: 'spot-check', nb: true },
          hidden: true
        },
        {
          path: 'spot-check-detail',
          name: 'spotCheckDetail',
          component: () => import('@/views/service/spot-check-detail/spot-check-detail'),
          meta: { noCache: true, title: '工单详情', activeMenu: 'spot-check', nb: true },
          hidden: true
        },
        {
          path: 'repairs',
          name: 'repairs',
          component: () => import('@/views/service/repairs/repairs'),
          meta: { noCache: false, title: '维修工单', activeMenu: 'repairs', buttons: serviceButtons.RepairsPlan('repairs') }
        },
        {
          path: 'repairs-detail',
          name: 'repairsDetail',
          component: () => import('@/views/service/repairs-detail/repairs-detail'),
          meta: { noCache: true, title: '工单详情', activeMenu: 'repairs', nb: true },
          hidden: true
        },
        {
          path: 'install-equipment',
          name: 'installEquipment',
          component: () => import('@/views/service/install-equipment/install-equipment/list'),
          meta: { noCache: false, title: '装机工单', activeMenu: 'install-equipment', buttons: serviceButtons.InstallEquipment('installEquipment') }
        },
        {
          path: 'install-equipment-detail',
          name: 'installEquipmentDetail',
          component: () => import('@/views/service/install-equipment/detail'),
          meta: { noCache: true, title: '工单详情', activeMenu: 'install-equipment', nb: true },
          hidden: true
        },
        {
          path: 'reform',
          name: 'reform',
          component: () => import('@/views/service/reform/reform'),
          meta: { noCache: false, title: '改造工单', activeMenu: 'reform', buttons: serviceButtons.Reform('reform') }
        },
        {
          path: 'reform-detail',
          name: 'reformDetail',
          component: () => import('@/views/service/repairs-detail/repairs-detail'),
          meta: { noCache: true, title: '工单详情', activeMenu: 'reform', nb: true },
          hidden: true
        },
        {
          path: 'replace',
          name: 'replace',
          component: () => import('@/views/service/replace/replace'),
          meta: { noCache: false, title: '更换工单', activeMenu: 'replace', buttons: serviceButtons.Reform('replace') }
        },
        {
          path: 'replace-detail',
          name: 'replaceDetail',
          component: () => import('@/views/service/repairs-detail/repairs-detail'),
          meta: { noCache: true, title: '工单详情', activeMenu: 'replace', nb: true },
          hidden: true
        }
      ]
    },
    {
      path: 'knowledge-base',
      name: 'knowledgeBase',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '企业知识库', activeMenu: 'knowledge-base' },
      redirect: '/service/knowledge-base/enterprise-files',
      children: [
        {
          path: 'enterprise-files',
          name: 'enterpriseFiles',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '企业文件', activeMenu: 'enterprise-files', canLink: true },
          redirect: '/service/knowledge-base/enterprise-files/list',
          children: [
            {
              path: 'list',
              name: 'enterpriseFilesList',
              component: () => import('@/views/service/knowledge-base/enterprise-files/list.vue'),
              meta: { roleTitle: '列表', title: '', noCache: true, activeMenu: 'enterprise-files', buttons: serviceButtons.EnterpriseFilesList('enterpriseFilesList') },
              hidden: true
            }
          ]
        },
        {
          path: 'failure-experience',
          name: 'failureExperience',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '故障经验', activeMenu: 'failure-experience', canLink: true },
          redirect: '/service/knowledge-base/failure-experience/list',
          children: [
            {
              path: 'list',
              name: 'failureExperienceList',
              component: () => import('@/views/service/knowledge-base/failure-experience/list'),
              meta: { roleTitle: '列表', title: '', noCache: true, activeMenu: 'failure-experience', buttons: serviceButtons.FailureExperienceList('failureExperienceList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addFailureExperience',
              component: () => import('@/views/service/knowledge-base/failure-experience/add'),
              meta: { roleTitle: '新增故障经验', title: '', noCache: true, activeMenu: 'failure-experience', nb: true },
              hidden: true
            }
          ]
        }
      ]
    },
    {
      path: 'work-management',
      name: 'workManagement',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '工作管理', activeMenu: 'work-management' },
      redirect: '/service/work-management/daily-report',
      children: [
        {
          path: 'daily-report',
          name: 'dailyReport',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '工作日报', activeMenu: 'daily-report', canLink: true },
          redirect: '/service/work-management/daily-report/list',
          children: [
            {
              path: 'list',
              name: 'dailyReportList',
              component: () => import('@/views/service/work-management/daily-report/list'),
              meta: { roleTitle: '列表', title: '', noCache: true, activeMenu: 'daily-report', buttons: serviceButtons.DailyReportList('dailyReportList') },
              hidden: true
            }
          ]
        }
      ]
    },
    {
      path: 'order-management',
      name: 'orderManagement',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '服务订单', activeMenu: 'order-management' },
      redirect: '/service/order-management/service-order',
      children: [
        {
          path: 'service-order',
          name: 'serviceOrder',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '服务订单', activeMenu: 'service-order', canLink: true },
          redirect: '/service/order-management/service-order/list',
          children: [
            {
              path: 'list',
              name: 'serviceOrderList',
              component: () => import('@/views/service/service-order/service-order'),
              meta: { roleTitle: '列表', title: '', noCache: true, activeMenu: 'service-order', buttons: serviceButtons.ServiceOrderList('serviceOrderList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addServiceOrder',
              component: () => import('@/views/service/service-order-detail/service-order-detail'),
              meta: { title: '服务订单', activeMenu: 'service-order', noCache: true, nb: true },
              hidden: true
            }
          ]
        }
      ]
    },
    {
      path: 'facility',
      name: 'facility',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '售后设备', icon: require('@/assets/l_c_S_images/售后设备@2x.png'), activeMenu: 'facility' },
      redirect: '/service/facility/facility-account/list',
          children: [
            {
              path: 'facility-account',
              name: 'facilityAccount',
              component: () => import('@/layout/blank.vue'),
              meta: { title: '设备台账', activeMenu: 'facility-account', canLink: true },
              redirect: '/service/facility/facility-account/list',
              children: [
                {
                  path: 'list',
                  name: 'facilityAccountList',
                  component: () => import('@/views/facility/account/account.vue'),
                  meta: { roleTitle: '设备列表', title: '', noCache: true, activeMenu: 'facility-account', buttons: facilityButtons.facilityAccountList('facilityAccountList') },
                  hidden: true
                },
                {
                  path: 'add',
                  name: 'addFacility',
                  component: () => import('@/views/facility/account/addFacility.vue'),
                  meta: { roleTitle: '新增设备', title: '', noCache: true, activeMenu: 'facility-account', nb: true },
                  hidden: true
                },
                {
                  path: 'detail/:id(\\d+)',
                  name: 'facilityAccountDetail',
                  component: () => import('@/views/facility/account/new-detail/detail.vue'),
                  meta: { title: '设备详情', activeMenu: 'facility-account', noCache: true, nb: true, buttons: facilityButtons.facilityAccountDetail('facilityAccountDetail') },
                  hidden: true
                }
              ]
            },
            // {
            //   path: 'facility-maintenance',
            //   name: 'facilityMaintenance',
            //   component: () => import('@/layout/blank.vue'),
            //   meta: { title: '保养计划', activeMenu: 'facility-maintenance', canLink: true },
            //   redirect: '/service/facility/facility-maintenance/list',
            //   children: [
            //     {
            //       path: 'list',
            //       name: 'facilityMaintenanceList',
            //       component: () => import('@/views/facility/facility-maintenance/list.vue'),
            //       meta: { roleTitle: '保养计划列表', title: '', noCache: true, activeMenu: 'facility-maintenance', buttons: facilityButtons.facilityMaintenanceList('facilityMaintenanceList') },
            //       hidden: true
            //     },
            //     {
            //       path: 'add',
            //       name: 'addFacilityMaintenance',
            //       component: () => import('@/views/facility/facility-maintenance/addFacilityMaintenance.vue'),
            //       meta: { roleTitle: '新增保养计划', title: '', noCache: true, activeMenu: 'facility-maintenance', nb: true },
            //       hidden: true
            //     },
            //     {
            //       path: 'detail',
            //       name: 'facilityMaintenanceDetail',
            //       component: () => import('@/views/facility/facility-maintenance/detail/detail.vue'),
            //       meta: { title: '保养计划详情', activeMenu: 'facility-maintenance', noCache: true, nb: true },
            //       hidden: true
            //     },
            //     {
            //       path: 'addTemplate',
            //       name: 'addFacilityMaintenanceTemplate',
            //       component: () => import('@/views/facility/facility-maintenance/addFacilityMaintenanceTemplate.vue'),
            //       meta: { roleTitle: '新增保养计划模板', title: '', noCache: true, activeMenu: 'facility-maintenance', nb: true },
            //       hidden: true
            //     },
            //     {
            //       path: 'detailTemplate',
            //       name: 'facilityMaintenanceTemplateDetail',
            //       component: () => import('@/views/facility/facility-maintenance/detailTemplate/detailTemplate.vue'),
            //       meta: { title: '保养计划模板详情', activeMenu: 'facility-maintenance', noCache: true, nb: true },
            //       hidden: true
            //     }
            //   ]
            // },
            // {
            //   path: 'facility-point',
            //   name: 'facilityPoint',
            //   component: () => import('@/layout/blank.vue'),
            //   meta: { title: '点检计划', activeMenu: 'facility-point', canLink: true },
            //   redirect: '/service/facility/facility-point/list',
            //   children: [
            //     {
            //       path: 'list',
            //       name: 'facilityPointList',
            //       component: () => import('@/views/facility/facility-point/list.vue'),
            //       meta: { roleTitle: '点检计划列表', title: '', noCache: true, activeMenu: 'facility-point', buttons: facilityButtons.facilityPointList('facilityPointList') },
            //       hidden: true
            //     },
            //     {
            //       path: 'add',
            //       name: 'addFacilityPoint',
            //       component: () => import('@/views/facility/facility-point/addFacilityPoint.vue'),
            //       meta: { roleTitle: '新增点检计划', title: '', noCache: true, activeMenu: 'facility-point', nb: true },
            //       hidden: true
            //     },
            //     {
            //       path: 'detail',
            //       name: 'facilityPointDetail',
            //       component: () => import('@/views/facility/facility-point/detail/detail.vue'),
            //       meta: { title: '点检计划详情', activeMenu: 'facility-point', noCache: true, nb: true },
            //       hidden: true
            //     },
            //     {
            //       path: 'addPointTemplate',
            //       name: 'addFacilityPointTemplate',
            //       component: () => import('@/views/facility/facility-point/addFacilityPointTemplate.vue'),
            //       meta: { roleTitle: '新增点检计划模板', title: '', noCache: true, activeMenu: 'facility-point', nb: true },
            //       hidden: true
            //     },
            //     {
            //       path: 'detailPointTemplate',
            //       name: 'facilityPointTemplateDetail',
            //       component: () => import('@/views/facility/facility-point/detailTemplate/detailTemplate.vue'),
            //       meta: { title: '点检计划模板详情', activeMenu: 'facility-point', noCache: true, nb: true },
            //       hidden: true
            //     }
            //   ]
            // },
            // {
            //   path: 'technical-parameter',
            //   name: 'technicalParameter',
            //   component: () => import('@/layout/blank.vue'),
            //   meta: { title: '技术参数', activeMenu: 'technical-parameter', canLink: true },
            //   redirect: '/service/facility/technical-parameter/list',
            //   children: [
            //     {
            //       path: 'list',
            //       name: 'technicalParameterList',
            //       component: () => import('@/views/facility/technical/technical.vue'),
            //       meta: { roleTitle: '技术参数列表', title: '', noCache: true, activeMenu: 'technical-parameter', buttons: facilityButtons.technicalParameterList('technicalParameterList') }
            //     },
            //     {
            //       path: 'add',
            //       name: 'technicalParameterAdd',
            //       component: () => import('@/views/facility/technical/technical-add/add.vue'),
            //       meta: { roleTitle: '技术参数', title: '', noCache: true, activeMenu: 'technical-parameter', nb: true }
            //     }
            //   ]
            // },
            // {
            //   path: 'configuration-parameter',
            //   name: 'configurationParameter',
            //   component: () => import('@/layout/blank.vue'),
            //   meta: { title: '配置模板', activeMenu: 'configuration-parameter', canLink: true },
            //   redirect: '/service/facility/configuration-parameter/list',
            //   children: [
            //     {
            //       path: 'list',
            //       name: 'configurationParameterList',
            //       component: () => import('@/views/facility/configuration/configuration.vue'),
            //       meta: { roleTitle: '配置模板列表', title: '', noCache: true, activeMenu: 'configuration-parameter', buttons: facilityButtons.configurationParameterList('configurationParameterList') },
            //       hidden: true
            //     },
            //     {
            //       path: 'add',
            //       name: 'addConfigurationParameter',
            //       component: () => import('@/views/facility/configuration/configuration-add/add.vue'),
            //       meta: { roleTitle: '配置模板', title: '', noCache: true, activeMenu: 'configuration-parameter', nb: true },
            //       hidden: true
            //     }
            //   ]
            // },
            // {
            //   path: 'factory-parameter',
            //   name: 'factoryParameter',
            //   component: () => import('@/layout/blank.vue'),
            //   meta: { title: '工厂参数', activeMenu: 'factory-parameter', canLink: true },
            //   redirect: '/service/facility/factory-parameter/list',
            //   children: [
            //     {
            //       path: 'list',
            //       name: 'factoryParameterList',
            //       component: () => import('@/views/facility/factory/factory-parameter.vue'),
            //       meta: { roleTitle: '工厂参数列表', title: '', noCache: true, activeMenu: 'factory-parameter', buttons: facilityButtons.factoryParameterList('factoryParameterList') }
            //     },
            //     {
            //       path: 'add',
            //       name: 'factoryParameterAdd',
            //       component: () => import('@/views/facility/factory/factory-add/add.vue'),
            //       meta: { roleTitle: '工厂参数模板', title: '', noCache: true, activeMenu: 'factory-parameter', nb: true }
            //     }
            //   ]
            // }
      ]
    },
    {
      path: 'tool-management',
      name: 'ToolManagement',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '工具管理', icon: require('@/assets/l_c_S_images/售后设备@2x.png'), activeMenu: 'tool-management' },
      redirect: '/service/tool-management/tool-ledger',
      children: [
        {
          path: 'tool-ledger',
          name: 'ToolLedger',
          component: () => import('@/views/service/tool-management/tool-ledger/index.vue'),
          meta: { title: '工具台账', noCache: true, activeMenu: 'tool-ledger', buttons: facilityButtons.ToolLedger('ToolLedger') }
        },
        {
          path: 'tool-ledger-detail/:id(\\d+)',
          name: 'ToolLedgerDetail',
          component: () => import('@/views/service/tool-management/tool-ledger/detail.vue'),
          meta: { title: '工具详情', activeMenu: 'tool-ledger', noCache: true, nb: true},
          hidden: true
        }
      ]
    },
    // {
    //   path: 'auxiliary-material-management',
    //   name: 'auxiliaryMaterialManagement',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '辅材管理', activeMenu: 'auxiliary-material-management' },
    //   redirect: '/service/auxiliary-material-management/auxiliary-materials/list',
    //   children: [
    //     {
    //       path: 'auxiliary-materials',
    //       name: 'auxiliaryMaterials',
    //       component: () => import('@/layout/blank.vue'),
    //       meta: { title: '辅材履历', activeMenu: 'auxiliary-materials', canLink: true },
    //       redirect: '/service/auxiliary-material-management/auxiliary-materials/list',
    //       children: [
    //         {
    //           path: 'list',
    //           name: 'auxiliaryMaterialsList',
    //           component: () => import('@/views/facility/auxiliary-materials/list.vue'),
    //           meta: { roleTitle: '辅材列表', title: '', noCache: true, activeMenu: 'auxiliary-materials', buttons: facilityButtons.auxiliaryMaterialsList('auxiliaryMaterialsList') },
    //           hidden: true
    //         },
    //         {
    //           path: 'detail/:id(\\d+)',
    //           name: 'auxiliaryMaterialsDetail',
    //           component: () => import('@/views/facility/auxiliary-materials/detail.vue'),
    //           meta: { title: '辅材详情', activeMenu: 'auxiliary-materials', noCache: true, nb: true, buttons: facilityButtons.auxiliaryMaterialsDetail('auxiliaryMaterialsDetail') },
    //           hidden: true
    //         }
    //       ]
    //     },
    //   ]
    // },
    //售后建模
    {
      path: 'afterSales-info',
      name: 'AfterSalesInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '基础数据', activeMenu: 'afterSales-info' },
      redirect: '/service/afterSales-info/customer-info',
      children: [
        {
          path: 'customer-info',
          name: 'customerInfo',
          component: () => import('@/views/information/customer/customer.vue'),
          meta: { title: '客户', activeMenu: 'customer-info', buttons: informationButtons.customerInfo('customerInfo') }
        },
        // {
        //   path: 'facility-account',
        //   name: 'facilityAccount',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '设备台账', activeMenu: 'facility-account', canLink: true },
        //   redirect: '/service/afterSales-info/facility-account/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'facilityAccountList',
        //       component: () => import('@/views/facility/account/account.vue'),
        //       meta: { roleTitle: '设备列表', title: '', noCache: true, activeMenu: 'facility-account', buttons: facilityButtons.facilityAccountList('facilityAccountList') },
        //       hidden: true
        //     },
        //     {
        //       path: 'add',
        //       name: 'addFacility',
        //       component: () => import('@/views/facility/account/addFacility.vue'),
        //       meta: { roleTitle: '新增设备', title: '', noCache: true, activeMenu: 'facility-account', nb: true },
        //       hidden: true
        //     },
        //     {
        //       path: 'detail/:id(\\d+)',
        //       name: 'facilityAccountDetail',
        //       component: () => import('@/views/facility/account/new-detail/detail.vue'),
        //       meta: { title: '设备详情', activeMenu: 'facility-account', noCache: true, nb: true, buttons: facilityButtons.facilityAccountDetail('facilityAccountDetail') },
        //       hidden: true
        //     }
        //   ]
        // },
        {
          path: 'facility-maintenance',
          name: 'facilityMaintenance',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '保养计划', activeMenu: 'facility-maintenance', canLink: true },
          redirect: '/service/afterSales-info/facility-maintenance/list',
          children: [
            {
              path: 'list',
              name: 'facilityMaintenanceList',
              component: () => import('@/views/facility/facility-maintenance/list.vue'),
              meta: { roleTitle: '保养计划列表', title: '', noCache: true, activeMenu: 'facility-maintenance', buttons: facilityButtons.facilityMaintenanceList('facilityMaintenanceList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addFacilityMaintenance',
              component: () => import('@/views/facility/facility-maintenance/addFacilityMaintenance.vue'),
              meta: { roleTitle: '新增保养计划', title: '', noCache: true, activeMenu: 'facility-maintenance', nb: true },
              hidden: true
            },
            {
              path: 'detail',
              name: 'facilityMaintenanceDetail',
              component: () => import('@/views/facility/facility-maintenance/detail/detail.vue'),
              meta: { title: '保养计划详情', activeMenu: 'facility-maintenance', noCache: true, nb: true },
              hidden: true
            },
            {
              path: 'addTemplate',
              name: 'addFacilityMaintenanceTemplate',
              component: () => import('@/views/facility/facility-maintenance/addFacilityMaintenanceTemplate.vue'),
              meta: { roleTitle: '新增保养计划模板', title: '', noCache: true, activeMenu: 'facility-maintenance', nb: true },
              hidden: true
            },
            {
              path: 'detailTemplate',
              name: 'facilityMaintenanceTemplateDetail',
              component: () => import('@/views/facility/facility-maintenance/detailTemplate/detailTemplate.vue'),
              meta: { title: '保养计划模板详情', activeMenu: 'facility-maintenance', noCache: true, nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'facility-point',
          name: 'facilityPoint',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '点检计划', activeMenu: 'facility-point', canLink: true },
          redirect: '/service/afterSales-info/facility-point/list',
          children: [
            {
              path: 'list',
              name: 'facilityPointList',
              component: () => import('@/views/facility/facility-point/list.vue'),
              meta: { roleTitle: '点检计划列表', title: '', noCache: true, activeMenu: 'facility-point', buttons: facilityButtons.facilityPointList('facilityPointList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addFacilityPoint',
              component: () => import('@/views/facility/facility-point/addFacilityPoint.vue'),
              meta: { roleTitle: '新增点检计划', title: '', noCache: true, activeMenu: 'facility-point', nb: true },
              hidden: true
            },
            {
              path: 'detail',
              name: 'facilityPointDetail',
              component: () => import('@/views/facility/facility-point/detail/detail.vue'),
              meta: { title: '点检计划详情', activeMenu: 'facility-point', noCache: true, nb: true },
              hidden: true
            },
            {
              path: 'addPointTemplate',
              name: 'addFacilityPointTemplate',
              component: () => import('@/views/facility/facility-point/addFacilityPointTemplate.vue'),
              meta: { roleTitle: '新增点检计划模板', title: '', noCache: true, activeMenu: 'facility-point', nb: true },
              hidden: true
            },
            {
              path: 'detailPointTemplate',
              name: 'facilityPointTemplateDetail',
              component: () => import('@/views/facility/facility-point/detailTemplate/detailTemplate.vue'),
              meta: { title: '点检计划模板详情', activeMenu: 'facility-point', noCache: true, nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'technical-parameter',
          name: 'technicalParameter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '技术参数', activeMenu: 'technical-parameter', canLink: true },
          redirect: '/service/afterSales-info/technical-parameter/list',
          children: [
            {
              path: 'list',
              name: 'technicalParameterList',
              component: () => import('@/views/facility/technical/technical.vue'),
              meta: { roleTitle: '技术参数列表', title: '', noCache: true, activeMenu: 'technical-parameter', buttons: facilityButtons.technicalParameterList('technicalParameterList') }
            },
            {
              path: 'add',
              name: 'technicalParameterAdd',
              component: () => import('@/views/facility/technical/technical-add/add.vue'),
              meta: { roleTitle: '技术参数', title: '', noCache: true, activeMenu: 'technical-parameter', nb: true }
            }
          ]
        },
        {
          path: 'configuration-parameter',
          name: 'configurationParameter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '配置模板', activeMenu: 'configuration-parameter', canLink: true },
          redirect: '/service/afterSales-info/configuration-parameter/list',
          children: [
            {
              path: 'list',
              name: 'configurationParameterList',
              component: () => import('@/views/facility/configuration/configuration.vue'),
              meta: { roleTitle: '配置模板列表', title: '', noCache: true, activeMenu: 'configuration-parameter', buttons: facilityButtons.configurationParameterList('configurationParameterList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addConfigurationParameter',
              component: () => import('@/views/facility/configuration/configuration-add/add.vue'),
              meta: { roleTitle: '配置模板', title: '', noCache: true, activeMenu: 'configuration-parameter', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'factory-parameter',
          name: 'factoryParameter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '工厂参数', activeMenu: 'factory-parameter', canLink: true },
          redirect: '/service/afterSales-info/factory-parameter/list',
          children: [
            {
              path: 'list',
              name: 'factoryParameterList',
              component: () => import('@/views/facility/factory/factory-parameter.vue'),
              meta: { roleTitle: '工厂参数列表', title: '', noCache: true, activeMenu: 'factory-parameter', buttons: facilityButtons.factoryParameterList('factoryParameterList') }
            },
            {
              path: 'add',
              name: 'factoryParameterAdd',
              component: () => import('@/views/facility/factory/factory-add/add.vue'),
              meta: { roleTitle: '工厂参数模板', title: '', noCache: true, activeMenu: 'factory-parameter', nb: true }
            }
          ]
        }
      ]
    },
  ]
}

