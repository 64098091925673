// debounceMixin.js

export const debounceMixin = {
    data() {
      return {
        isButtonDisabled: false
      };
    },
    methods: {
      debouncedClickHandler(callback, debounceTime = 2000) {
        if (!this.isButtonDisabled) {
          callback(); // 执行按钮点击事件处理逻辑
          
          this.isButtonDisabled = true; // 禁用按钮
  
          setTimeout(() => {
            this.isButtonDisabled = false; // 启用按钮
          }, debounceTime);
        }
      }
    }
  };
  