import facilityButtons from './page-buttons/xiewei/facility.buttons'
export default {
  path: 'facility',
  name: 'facility',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '售后管理', icon: require('@/assets/l_c_S_images/售后管理.svg'), activeMenu: 'facility' },
  redirect: '/facility/all-facility/facility/list',
  children: [
    {
      path: 'all-facility',
      name: 'allFacility',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '设备管理', activeMenu: 'all-facility' },
      redirect: '/facility/all-facility/facility/list',
      children: [
        {
          path: 'facility',
          name: 'facilityAccount',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '设备台账', activeMenu: 'facility', canLink: true },
          redirect: '/facility/all-facility/facility/list',
          children: [
            {
              path: 'list',
              name: 'facilityAccountList',
              component: () => import('@/views/facility/account/account.vue'),
              meta: { roleTitle: '设备列表', title: '', noCache: true, activeMenu: 'facility', buttons: facilityButtons.facilityAccountList('facilityAccountList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addFacility',
              component: () => import('@/views/facility/account/addFacility.vue'),
              meta: { roleTitle: '新增设备', title: '', noCache: true, activeMenu: 'facility', nb: true },
              hidden: true
            },
            {
              path: 'detail/:id(\\d+)',
              name: 'facilityAccountDetail',
              component: () => import('@/views/facility/account/new-detail/detail.vue'),
              meta: { title: '设备详情', activeMenu: 'facility', noCache: true, nb: true, buttons: facilityButtons.facilityAccountDetail('facilityAccountDetail') },
              hidden: true
            }
          ]
        },
        {
          path: 'facility-maintenance',
          name: 'facilityMaintenance',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '保养计划', activeMenu: 'facility-maintenance', canLink: true },
          redirect: '/facility/all-facility/facility-maintenance/list',
          children: [
            {
              path: 'list',
              name: 'facilityMaintenanceList',
              component: () => import('@/views/facility/facility-maintenance/list.vue'),
              meta: { roleTitle: '保养计划列表', title: '', noCache: true, activeMenu: 'facility-maintenance', buttons: facilityButtons.facilityMaintenanceList('facilityMaintenanceList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addFacilityMaintenance',
              component: () => import('@/views/facility/facility-maintenance/addFacilityMaintenance.vue'),
              meta: { roleTitle: '新增保养计划', title: '', noCache: true, activeMenu: 'facility-maintenance', nb: true },
              hidden: true
            },
            {
              path: 'detail',
              name: 'facilityMaintenanceDetail',
              component: () => import('@/views/facility/facility-maintenance/detail/detail.vue'),
              meta: { title: '保养计划详情', activeMenu: 'facility-maintenance', noCache: true, nb: true },
              hidden: true
            },
            {
              path: 'addTemplate',
              name: 'addFacilityMaintenanceTemplate',
              component: () => import('@/views/facility/facility-maintenance/addFacilityMaintenanceTemplate.vue'),
              meta: { roleTitle: '新增保养计划模板', title: '', noCache: true, activeMenu: 'facility-maintenance', nb: true },
              hidden: true
            },
            {
              path: 'detailTemplate',
              name: 'facilityMaintenanceTemplateDetail',
              component: () => import('@/views/facility/facility-maintenance/detailTemplate/detailTemplate.vue'),
              meta: { title: '保养计划模板详情', activeMenu: 'facility-maintenance', noCache: true, nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'facility-point',
          name: 'facilityPoint',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '点检计划', activeMenu: 'facility-point', canLink: true },
          redirect: '/facility/all-facility/facility-point/list',
          children: [
            {
              path: 'list',
              name: 'facilityPointList',
              component: () => import('@/views/facility/facility-point/list.vue'),
              meta: { roleTitle: '点检计划列表', title: '', noCache: true, activeMenu: 'facility-point', buttons: facilityButtons.facilityPointList('facilityPointList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addFacilityPoint',
              component: () => import('@/views/facility/facility-point/addFacilityPoint.vue'),
              meta: { roleTitle: '新增点检计划', title: '', noCache: true, activeMenu: 'facility-point', nb: true },
              hidden: true
            },
            {
              path: 'detail',
              name: 'facilityPointDetail',
              component: () => import('@/views/facility/facility-point/detail/detail.vue'),
              meta: { title: '点检计划详情', activeMenu: 'facility-point', noCache: true, nb: true },
              hidden: true
            },
            {
              path: 'addPointTemplate',
              name: 'addFacilityPointTemplate',
              component: () => import('@/views/facility/facility-point/addFacilityPointTemplate.vue'),
              meta: { roleTitle: '新增点检计划模板', title: '', noCache: true, activeMenu: 'facility-point', nb: true },
              hidden: true
            },
            {
              path: 'detailPointTemplate',
              name: 'facilityPointTemplateDetail',
              component: () => import('@/views/facility/facility-point/detailTemplate/detailTemplate.vue'),
              meta: { title: '点检计划模板详情', activeMenu: 'facility-point', noCache: true, nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'technical-parameter',
          name: 'technicalParameter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '技术参数', activeMenu: 'technical-parameter', canLink: true },
          redirect: '/facility/all-facility/technical-parameter/list',
          children: [
            {
              path: 'list',
              name: 'technicalParameterList',
              component: () => import('@/views/facility/technical/technical.vue'),
              meta: { roleTitle: '技术参数列表', title: '', noCache: true, activeMenu: 'technical-parameter', buttons: facilityButtons.technicalParameterList('technicalParameterList') }
            },
            {
              path: 'add',
              name: 'technicalParameterAdd',
              component: () => import('@/views/facility/technical/technical-add/add.vue'),
              meta: { roleTitle: '技术参数', title: '', noCache: true, activeMenu: 'technical-parameter', nb: true }
            }
          ]
        },
        {
          path: 'configuration-parameter',
          name: 'configurationParameter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '配置模板', activeMenu: 'configuration-parameter', canLink: true },
          redirect: '/facility/all-facility/configuration-parameter/list',
          children: [
            {
              path: 'list',
              name: 'configurationParameterList',
              component: () => import('@/views/facility/configuration/configuration.vue'),
              meta: { roleTitle: '配置模板列表', title: '', noCache: true, activeMenu: 'configuration-parameter', buttons: facilityButtons.configurationParameterList('configurationParameterList') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addConfigurationParameter',
              component: () => import('@/views/facility/configuration/configuration-add/add.vue'),
              meta: { roleTitle: '配置模板', title: '', noCache: true, activeMenu: 'configuration-parameter', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'factory-parameter',
          name: 'factoryParameter',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '工厂参数', activeMenu: 'factory-parameter', canLink: true },
          redirect: '/facility/all-facility/factory-parameter/list',
          children: [
            {
              path: 'list',
              name: 'factoryParameterList',
              component: () => import('@/views/facility/factory/factory-parameter.vue'),
              meta: { roleTitle: '工厂参数列表', title: '', noCache: true, activeMenu: 'factory-parameter', buttons: facilityButtons.factoryParameterList('factoryParameterList') }
            },
            {
              path: 'add',
              name: 'factoryParameterAdd',
              component: () => import('@/views/facility/factory/factory-add/add.vue'),
              meta: { roleTitle: '工厂参数模板', title: '', noCache: true, activeMenu: 'factory-parameter', nb: true }
            }
          ]
        }
      ]
    },
    // {
    //   path: 'auxiliary-material-management',
    //   name: 'auxiliaryMaterialManagement',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '辅材管理', activeMenu: 'auxiliary-material-management' },
    //   redirect: '/facility/auxiliary-material-management/facility/list',
    //   children: [
    //     {
    //       path: 'auxiliary-materials',
    //       name: 'auxiliaryMaterials',
    //       component: () => import('@/layout/blank.vue'),
    //       meta: { title: '辅材履历', activeMenu: 'auxiliary-materials', canLink: true },
    //       redirect: '/facility/auxiliary-material-management/auxiliary-materials/list',
    //       children: [
    //         {
    //           path: 'list',
    //           name: 'auxiliaryMaterialsList',
    //           component: () => import('@/views/facility/auxiliary-materials/list.vue'),
    //           meta: { roleTitle: '辅材列表', title: '', noCache: true, activeMenu: 'auxiliary-materials', buttons: facilityButtons.auxiliaryMaterialsList('auxiliaryMaterialsList') },
    //           hidden: true
    //         },
    //         {
    //           path: 'detail/:id(\\d+)',
    //           name: 'auxiliaryMaterialsDetail',
    //           component: () => import('@/views/facility/auxiliary-materials/detail.vue'),
    //           meta: { title: '辅材详情', activeMenu: 'auxiliary-materials', noCache: true, nb: true, buttons: facilityButtons.auxiliaryMaterialsDetail('auxiliaryMaterialsDetail') },
    //           hidden: true
    //         }
    //       ]
    //     },
    //   ]
    // }
  ]
}
