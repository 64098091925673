import siteWarehouseButtons from './page-buttons/site-warehouse.buttons'
export default {
  path: 'site-warehouse',
  name: 'siteWarehouse',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '线边仓', icon: require('@/assets/l_c_S_images/线边仓.svg'), activeMenu: 'site-warehouse' },
  redirect: '/site-warehouse/warehouses-info/warehouses',
  children: [
    {
      path: 'warehouses-info',
      name: 'warehousesInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '线边仓管理', activeMenu: 'warehouses-info' },
      children: [
        {
          path: 'warehouses',
          name: 'warehouses',
          component: () => import('@/views/site-warehouse/warehouse/warehouse.vue'),
          meta: { title: '线边库存', activeMenu: 'warehouses', buttons: siteWarehouseButtons.warehouses }
        },
        {
          path: 'sit-warehouse-account',
          name: 'sitWarehouseAccount',
          component: () => import('@/views/site-warehouse/sitWarehouseAccount/sitWarehouseAccount.vue'),
          meta: { title: '线边流水', activeMenu: 'sit-warehouse-account', buttons: siteWarehouseButtons.sitWarehouseAccount('sitWarehouseAccount') }
        }
      ]
    }
  ]
}
