const qualityPlanCommon = (name, radio) => [
  {
    name: name + radio + 'List',
    title: '列表'
  },
  {
    name: name + radio + 'Add',
    title: '新增'
  },
  {
    name: name + radio + 'Modify',
    title: '修改'
  },
  {
    name: name + radio + 'Copy',
    title: '复制'
  },
  {
    name: name + radio + 'Del',
    title: '删除'
  },
  {
    name: name + radio + 'ViewData',
    title: '查看数据'
  }
]

const baseCommonButtons = (name) => [
  {
    name: name + 'List',
    title: '列表'
  },
  {
    name: name + 'Add',
    title: '新增'
  },
  {
    name: name + 'Modify',
    title: '修改'
  },
  {
    name: name + 'Copy',
    title: '复制'
  },
  {
    name: name + 'Del',
    title: '删除'
  },
  {
    name: name + 'Status',
    title: '启/停用'
  },
  {
    name: name + 'Import',
    title: '导入'
  },
  {
    name: name + 'Export',
    title: '导出'
  }
]

export default {
  baseCommonButtons,
  qualityTemplate: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    }
  ],
  qualityPlan: (name) => [
    {
      name: name + 'AMaterialList',
      title: '物料',
      children: qualityPlanCommon(name, 'Material')
    },
    {
      name: name + 'BEquipmentList',
      title: '设备',
      children: qualityPlanCommon(name, 'Equipment')
    },
    {
      name: name + 'CAreasList',
      title: '区域',
      children: qualityPlanCommon(name, 'Areas')
    }
  ],
  unquality: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'TransferScrap',
      title: '移至废品仓'
    }
  ],
  inspectionMethods: baseCommonButtons,
  inspectionStandard: baseCommonButtons,
  inspectionItems: baseCommonButtons,
  // inspectionProject: baseCommonButtons,
  inspectionProject: (name) => [
    {
      name: name + 'ToHot',
      title: '加入常用库'
    },
    {
      name: name + 'BatchDel',
      title: '批量删除'
    },
    ...baseCommonButtons(name)
  ],
  defects: (name) => [
    {
      name: name + 'ADefectsItemList',
      title: '缺陷项',
      children: baseCommonButtons(name + 'DefectsItem')
    },
    {
      name: name + 'ADefectsReasonList',
      title: '缺陷原因',
      children: baseCommonButtons(name + 'DefectsReason')
    }
  ],
  qualityInspectionPlanList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Synchronous',
      title: '同步至质检单'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    }
  ],
  qualityInspectionOrderList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'check',
      title: '检验'
    },
    {
      name: name + 'print',
      title: '打印'
    },
    {
      name: name + 'modify',
      title: '修改'
    },
    {
      name: name + 'del',
      title: '删除'
    },
    {
      name: name + 'inspector',
      title: '质检员'
    }
  ],
  repairRecord: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'check',
      title: '创建计划'
    }
  ],
  reportWasteRecord: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'check',
      title: '移至废品仓'
    },
    {
      name: name + 'create',
      title: '创建计划'
    }
  ],
  scrap: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  finishedProductTracing: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Download',
      title: '下载'
    }
  ],
 qualityTask: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Detail',
      title: '详情'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Print',
      title: '打印'
    }
  ],
  defectiveProductJudge: (name) => [
     {
       name: name + 'List',
       title: '列表'
     },
     {
       name: name + 'Detail',
       title: '详情'
     }
   ],
   defectiveProductTask: (name) => [
      {
        name: name + 'List',
        title: '列表'
      },
      {
        name: name + 'Detail',
        title: '详情'
      }
    ]
}
