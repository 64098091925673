export default {
  path: 'self-center',
  name: 'selfCenter',
  hidden: true,
  component: () => import('@/views/self-center/self-center.vue'),
  meta: { noCache: true, title: '个人中心', icon: '', activeMenu: 'self-center', noAuth: true },
  redirect: '/self-center/user-info',
  children: [
    {
      path: 'user-info',
      name: 'userInfo',
      component: () => import('@/views/self-center/user-info/user-info.vue'),
      meta: { noCache: true, title: '用户信息', activeMenu: 'self-center', noAuth: true,
        breadcrumbNeste: [
          {
            path: 'self-center',
            title: '个人中心'
          },
          {
            path: 'self-center/user-info',
            title: '用户信息'
          }
        ]
      }
    },
    {
      path: 'modify-password',
      name: 'modifyPassword',
      component: () => import('@/views/self-center/modify-password/modify-password.vue'),
      meta: { noCache: true, title: '修改密码', activeMenu: 'modify-password', noAuth: true, breadcrumbNeste: [
        {
          path: 'self-center',
          title: '个人中心'
        },
        {
          path: 'self-center/modify-password',
          title: '修改密码'
        }
      ] }
    },
    {
      path: 'switch-shift',
      name: 'switchShift',
      component: () => import('@/views/self-center/switch-shift/switch-shift.vue'),
      meta: { noCache: true, title: '切换班次', activeMenu: 'switch-shift', noAuth: true, breadcrumbNeste: [
        {
          path: 'self-center',
          title: '个人中心'
        },
        {
          path: 'self-center/switch-shift',
          title: '切换班次'
        }
      ] }
    },
    {
      path: 'mine-equipment',
      name: 'mineEquipment',
      component: () => import('@/views/self-center/mine-equipment/mine-equipment.vue'),
      meta: { noCache: true, title: '我的设备', activeMenu: 'mine-equipment', noAuth: true, breadcrumbNeste: [
        {
          path: 'self-center',
          title: '个人中心'
        },
        {
          path: 'self-center/mine-equipment',
          title: '我的设备'
        }
      ] }
    }
  ]
}
