
export default {
  data() {
    return {
      isShowChild: true
    }
  },
  activated() {
    this.isShowChild = true
  },
  deactivated() {
    this.isShowChild = false
  }
}
