export default {
  mouldDefine: (name) => ([
    {
      name: name + 'Add',
      title: '新建'
    },
    {
      name: name + 'Edit',
      title: '编辑'
    },
    {
      name: name + 'View',
      title: '详情'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ]),
  mouldStock: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'InStock',
      title: '入库'
    },
    {
      name: name + 'OutStock',
      title: '出库'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Edit',
      title: '编辑'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'Notice',
      title: '预警通知'
    },
    {
      name: name + 'Record',
      title: '报工记录'
    }
  ])
}
