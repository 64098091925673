import informationButtons from './page-buttons/information.buttons'
import settingButtons from './page-buttons/setting.button'
import qualityButtons from './page-buttons/quality.buttons'
import callButtons from './page-buttons/call.buttons'
import facilityButtons from './page-buttons/xiewei/facility.buttons'

const equipmentAccountList = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Copy',
      title: '复制'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    // {
    //   name: name + 'AddRepair',
    //   title: '新建维保任务'
    // },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'PrintCode',
      title: '打印设备条码'
    },
    {
      name: name + 'Import',
      title: '导入'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ]
}

const structureBtn = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启/停用'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ]
}

const RolePermissionsBtn = function(name){
  const bases = [
    {
      name: name + 'Role',
      title: '功能权限',
      children: [
        {
          name: name + 'RoleList',
          title: '列表'
        },
        {
          name: name + 'RoleAdd',
          title: '新增'
        },
        {
          name: name + 'RoleModify',
          title: '修改'
        },
        {
          name: name + 'RoleDel',
          title: '删除'
        },
        {
          name: name + 'RoleStatus',
          title: '启/停用'
        },
        {
          name: name + 'RoleExport',
          title: '导出'
        }
      ]
    },
    {
      name: name + 'BUserList',
      title: '关联用户',
      children: [
        {
          name: name + 'UserSetStations',
          title: '设置工位'
        },
        {
          name: name + 'UserSetEquipments',
          title: '设置设备'
        },
        {
          name: name + 'UserModify',
          title: '修改'
        },
        {
          name: name + 'UserDel',
          title: '删除'
        }
      ]
    }
  ]
  return bases;
}
const printingModuleList = function(name){
  return [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'DownLoad',
      title: '下载'
    },
  ]
}

export default {
  path: 'information',
  name: 'information',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '工厂建模', icon: require('@/assets/l_c_S_images/工厂建模.svg'), activeMenu: 'information' },
  redirect: '/information/production-info/factory-model',
  children: [
    // 工厂建模
    {
      path: 'production-info',
      name: 'productionInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '工厂建模', activeMenu: 'production-info' },
      redirect: '/information/production-info/factory-model',
      children: [
        {
          path: 'factory-model',
          name: 'factoryModel',
          component: () => import('@/views/information/production/factory-model-refactor/factory-model.vue'),
          meta: { title: '区域', activeMenu: 'factory-model', buttons: informationButtons.factoryModel('factoryModel') }
        },
        {
          path: 'station',
          name: 'station',
          component: () => import('@/views/information/production/station/station.vue'),
          meta: { title: '工位', activeMenu: 'station', buttons: informationButtons.station('station') }
        },
        {
          path: 'equipment-info',
          name: 'EquipmentInfo',
          component: () => import('@/views/equipment/account/account.vue'),
          meta: { title: '设备', activeMenu: 'equipment-info', buttons: equipmentAccountList('EquipmentInfo') }
        },
      ]
    },
    // 组织建模
    {
      path: 'organizational-info',
      name: 'OrganizationalInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '组织建模', activeMenu: 'organizational-info' },
      redirect: '/information/organizational-info/structure',
      children: [
        {
          path: 'structure',
          name: 'Structure',
          component: () => import('@/views/setting/framework/framework.vue'),
          meta: { title: '组织架构', icon: '', activeMenu: 'structure', buttons: structureBtn('Structure') }
        },
        {
          path: 'role-permissions',
          name: 'RolePermissions',
          component: () => import('@/views/setting/role/role.vue'),
          meta: {
            title: '角色权限',
            icon: 'role',
            activeMenu: 'role-permissions',
            noCache: true,
            buttons: RolePermissionsBtn('RolePermissions')
          }
        },
        {
          path: 'users-info',
          name: 'UsersInfo',
          component: () => import('@/views/setting/user/user.vue'),
          meta: { title: '用户', icon: 'user', activeMenu: 'UsersInfo', noCache: true, buttons: settingButtons.user("UsersInfo") }
        },
        {
          path: 'shifts-info',
          name: 'ShiftsInfo',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '班次', activeMenu: 'shifts-info', canLink: true },
          redirect: '/information/organizational-info/shifts-info/list',
          children: [
            {
              path: 'list',
              name: 'ShiftsList',
              component: () => import('@/views/information/production/shifts/list.vue'),
              meta: {
                roleTitle: '班次列表',
                title: '',
                activeMenu: 'shifts-info',
                buttons: informationButtons.ShiftsList('ShiftsList')
              }
            },
            {
              path: 'detail',
              name: 'ShiftsDetail',
              component: () => import('@/views/information/production/shifts/detail/detail.vue'),
              meta: {
                title: '班次详情',
                activeMenu: 'shifts-info',
                noCache: true,
                nb: true
              }
            }
          ]
        },
        {
          path: 'calendars-info',
          name: 'CalendarsInfo',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '日历', activeMenu: 'calendars-info', canLink: true },
          redirect: '/information/organizational-info/calendars-info/list',
          children: [
            {
              path: 'list',
              name: 'CalendarList',
              component: () => import('@/views/information/production/calendar/list.vue'),
              meta: {
                roleTitle: '日历列表',
                title: '',
                activeMenu: 'calendars-info',
                buttons: informationButtons.CalendarList('CalendarList')
              }
            },
            {
              path: 'detail',
              name: 'CalendarDetail',
              component: () => import('@/views/information/production/calendar/detail/detail.vue'),
              meta: {
                title: '日历详情',
                activeMenu: 'calendars-info',
                noCache: true
              }
            }
          ]
        },
        // {
        //   path: 'work-shift',
        //   name: 'WorkShift',
        //   component: () => import('@/views/workCalendar/workShift.vue'),
        //   meta: { title: '工作时段', activeMenu: 'work-shift', buttons: informationButtons.WorkShift('WorkShift') }
        // }
      ]
    },
    // 物料建模
    {
      path: 'materials-info',
      name: 'materialsInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '物料建模', activeMenu: 'materials-info' },
      redirect: '/information/materials-info/materials-attribute',
      children: [
        {
          path: 'materials',
          name: 'materials',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '物料', activeMenu: 'materials', canLink: true },
          redirect: '/information/materials-info/materials/list',
          children: [
            {
              path: 'list',
              name: 'materialsList',
              component: () => import('@/views/information/materials/materials.vue'),
              meta: {
                title: '物料',
                activeMenu: 'materials',
                buttons: informationButtons.materialsList('materialsList')
              },
              hidden: true
            },
            {
              path: 'detail',
              name: 'materialsDetail',
              component: () => import('@/views/information/materials/detail/detail.vue'),
              meta: { title: '物料详情', activeMenu: 'materials', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'bom',
          name: 'bom',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '物料清单', activeMenu: 'bom', canLink: true },
          redirect: '/information/materials-info/bom/list',
          children: [
            {
              path: 'list',
              name: 'bomList',
              component: () => import('@/views/information/bom/bom.vue'),
              meta: { title: 'BOM列表', activeMenu: 'bom', buttons: informationButtons.bomList('bomList') },
              hidden: true
            },
            {
              path: 'detail',
              name: 'bomDetail',
              component: () => import('@/views/information/bom/detail/detail.vue'),
              meta: { title: 'BOM详情', activeMenu: 'bom', nb: true, buttons: informationButtons.bomDetail('bomDetail') },
              hidden: true
            },
            {
              path: 'mbom-detail',
              name: 'mbomDetail',
              component: () => import('@/views/information/bom/detail/mbom-detail.vue'),
              meta: {
                title: 'MBOM详情',
                activeMenu: 'bom',
                nb: true,
                buttons: informationButtons.mbomDetail('mbomDetail')
              },
              hidden: true
            },
            {
              path: 'change-mbom',
              name: 'changeToMbom',
              component: () => import('@/views/information/bom/detail/change-mbom.vue'),
              meta: { title: 'BOM转MBOM', activeMenu: 'bom', nb: true },
              hidden: true
            }
          ]
        },
        // {
        //   path: 'materials-balance',
        //   name: 'materialsBalance',
        //   component: () => import('@/views/information/materials-balance/materials-balance.vue'),
        //   meta: { title: '物料平衡', activeMenu: 'materials-balance', buttons: informationButtons.materialsBalance('materialsBalance')}
        // },
        // {
        //   path: 'materials-require',
        //   name: 'materialsRequire',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '物料需求', activeMenu: 'materials-require', canLink: true },
        //   redirect: '/information/materials-info/materials-require/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'materialsRequireList',
        //       component: () => import('@/views/information/materials-require/materials-require.vue'),
        //       meta: { title: '需求单列表', activeMenu: 'materials-require', buttons: informationButtons.materialsRequireList('materialsRequireList')},
        //       hidden: true
        //     },
        //     {
        //       path: 'detail',
        //       name: 'materialsRequireDetail',
        //       component: () => import('@/views/information/materials-require/detail/detail.vue'),
        //       meta: { title: '需求单详情', noCache: true, activeMenu: 'materials-require', nb: true,buttons: informationButtons.materialsRequireDetail('materialsRequireDetail') },
        //       hidden: true
        //     },
        //   ]
        // },
        // {
        //   path: 'materials-apply',
        //   name: 'materialsApply',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '领退料单', activeMenu: 'materials-apply', canLink: true },
        //   redirect: '/information/materials-info/materials-apply/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'materialsApplyList',
        //       component: () => import('@/views/information/materials-apply/materials-apply.vue'),
        //       meta: { title: '领退料单列表', activeMenu: 'materials-apply', buttons: informationButtons.materialsApplyList('materialsApplyList')},
        //       hidden: true
        //     },
        //     {
        //       path: 'detail',
        //       name: 'materialsApplyDetail',
        //       component: () => import('@/views/information/materials-apply/detail/detail.vue'),
        //       meta: { title: '领料单详情', noCache: true, activeMenu: 'materials-apply', nb: true, buttons: informationButtons.materialsApplyDetail('materialsApplyDetail') },
        //       hidden: true
        //     },
        //     {
        //       path: 'return-detail',
        //       name: 'materialsApplyReturnDetail',
        //       component: () => import('@/views/information/materials-apply/detail/return-detail.vue'),
        //       meta: { title: '退料单详情', noCache: true, activeMenu: 'materials-apply', nb: true,buttons: informationButtons.materialsApplyReturnDetail('materialsApplyReturnDetail') },
        //       hidden: true
        //     },
        //   ]
        // },
        {
          path: 'materials-setting',
          name: 'materialsSetting',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '物料配置', activeMenu: 'materials-setting', canLink: true },
          redirect: '/information/materials-info/materials-setting/materials-setting-list',
          children: [
            {
              path: 'materials-setting-list',
              name: 'materialsSettingList',
              component: () => import('@/views/information/materials-setting/materials-setting.vue'),
              meta: { roleTitle: '物料配置列表', title: '', activeMenu: 'materials-setting', buttons: informationButtons.materialsSettingList }
            },
            {
              path: 'edit-bom',
              name: 'editBom',
              component: () => import('@/views/information/materials-setting/edit-bom.vue'),
              meta: { title: '编辑BOM', activeMenu: 'materials-setting', nb: true }
            }
          ]
        },
        {
          path: 'materials-type-info',
          name: 'MaterialsType',
          component: () => import('@/views/information/materials/materials-attribute/materials-attribute.vue'),
          meta: {
            title: '物料类型',
            activeMenu: 'materials-type-info',
            buttons: informationButtons.materialsAttributeCommonFor('MaterialsTypeMaterialsType'),
            nameFlage:'MaterialsType'
          }
        },
        {
          path: 'material-properties-info',
          name: 'MaterialsAttribute',
          component: () => import('@/views/information/materials/materials-attribute/materials-attribute.vue'),
          meta: {
            title: '物料属性',
            activeMenu: 'material-properties-info',
            buttons: informationButtons.materialsAttributeCommonFor('MaterialsAttributeMaterialsAttribute'),
            nameFlage:'MaterialsAttribute'
          }
        },
        {
          path: 'materials-essence-info',
          name: 'MaterialsQuality',
          component: () => import('@/views/information/materials/materials-attribute/materials-attribute.vue'),
          meta: {
            title: '物料材质',
            activeMenu: 'materials-essence-info',
            buttons: informationButtons.materialsAttributeCommonFor('MaterialsQualityMaterialsQuality'),
            nameFlage:'MaterialsQuality'
          }
        },
        {
          path: 'materials-unit-info',
          name: 'MaterialsUnit',
          component: () => import('@/views/information/materials/materials-attribute/materials-attribute.vue'),
          meta: {
            title: '计量单位',
            activeMenu: 'materials-unit-info',
            buttons: [
              ...informationButtons.materialsAttributeCommonFor('MaterialsUnitMaterialsUnit'),
              {
                name: 'MaterialsUnitMaterialsUnit' + 'Import',
                title: '导入'
              },
              {
                name: 'MaterialsUnitMaterialsUnit' + 'Export',
                title: '导出'
              }
            ],
            nameFlage:'MaterialsUnit'
          }
        }
      ]
    },
    // 工艺建模
    {
      path: 'factory-info',
      name: 'factoryInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '工艺建模', activeMenu: 'factoryInfo-info' },
      redirect: '/information/factory-info/procedure/list',
      children: [
        // {
        //   path: 'resource',
        //   name: 'Resource',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '资源', activeMenu: 'resource', canLink: true },
        //   redirect: '/information/factory-info/resource/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'ResourceList',
        //       component: () => import('@/views/information/production/resource/list.vue'),
        //       meta: {
        //         roleTitle: '资源列表',
        //         title: '',
        //         activeMenu: 'resource',
        //         buttons: informationButtons.ResourceList('ResourceList')
        //       }
        //     },
        //     {
        //       path: 'detail',
        //       name: 'ResourceDetail',
        //       component: () => import('@/views/information/production/resource/detail/detail.vue'),
        //       meta: {
        //         title: '资源详情',
        //         activeMenu: 'resource',
        //         noCache: true,
        //         nb: true
        //       }
        //     }
        //   ]
        // },
        // {
        //   path: 'workCenter',
        //   name: 'WorkCenter',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '工作中心', activeMenu: 'workCenter', canLink: true },
        //   redirect: '/information/factory-info/workCenter/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'WorkCenterList',
        //       component: () => import('@/views/information/production/workCenter/list.vue'),
        //       meta: {
        //         roleTitle: '工作中心列表',
        //         title: '',
        //         activeMenu: 'workCenter',
        //         buttons: informationButtons.WorkCenterList('WorkCenterList')
        //       }
        //     },
        //     {
        //       path: 'detail',
        //       name: 'WorkCenterDetail',
        //       component: () => import('@/views/information/production/workCenter/detail/detail.vue'),
        //       meta: {
        //         title: '工作中心详情',
        //         activeMenu: 'workCenter',
        //         noCache: true,
        //         nb: true
        //       }
        //     }
        //   ]
        // },
        {
          path: 'procedure',
          name: 'procedure',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '工序定义', activeMenu: 'procedure', canLink: true },
          redirect: '/information/factory-info/procedure/list',
          children: [
            {
              path: 'list',
              name: 'procedureList',
              component: () => import('@/views/information/production/procedure/procedure.vue'),
              meta: {
                roleTitle: '工序列表',
                title: '',
                activeMenu: 'procedure',
                buttons: informationButtons.procedureList('procedureList')
              }
            },
            {
              path: 'detail',
              name: 'procedureDetail',
              component: () => import('@/views/information/production/procedure/detail/detail.vue'),
              meta: {
                title: '工序详情',
                activeMenu: 'procedure',
                nb: true,
                buttons: informationButtons.procedureDetail('procedureDetail')
              }
            }
          ]
        },
        {
          path: 'process',
          name: 'process',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '工艺路线', activeMenu: 'process', canLink: true },
          redirect: '/information/factory-info/process/list',
          children: [
            {
              path: 'list',
              name: 'processList',
              component: () => import('@/views/information/production/process/process.vue'),
              meta: {
                roleTitle: '工艺列表',
                noCache: true,
                title: '',
                activeMenu: 'process',
                buttons: informationButtons.processList('processList')
              }
            },
            {
              path: 'detail',
              name: 'processDetail',
              component: () => import('@/views/information/production/process/detail/detail.vue'),
              meta: {
                noCache: true,
                title: '工艺详情',
                activeMenu: 'process',
                nb: true,
                buttons: informationButtons.processDetail('processDetail')
              }
            }
          ]
        },
        {
          path: 'piece-rate',
          name: 'PieceRate',
          component: () => import('@/views/plans/settings/index.vue'),
          meta: {
            noCache: true,
            title: '计件工资',
            activeMenu: 'piece-rate',
            buttons: informationButtons.plansSettings('PieceRate')
          }
        },
        {
          path: 'workhours-reason',
          name: 'WorkHoursReason',
          component: () => import('@/views/information/production/workhours-reason/list.vue'),
          meta: {
            noCache: true,
            title: '工时原因',
            activeMenu: 'workhours-reason',
            buttons: informationButtons.WorkHoursReason('WorkHoursReason')
          }
        },
        {
          path: 'procedure-params',
          name: 'procedureParams',
          component: () => import('@/views/setting/params/procedure/procedure.vue'),
          meta: {
            title: '工序参数',
            icon: 'flight-managment',
            activeMenu: 'procedure-params',
            buttons: informationButtons.procedureParams('procedureParams')
          }
        },
        // {
        //   path: 'resource',
        //   name: 'Resource',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '资源', activeMenu: 'resource', canLink: true },
        //   redirect: '/information/factory-info/resource/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'ResourceList',
        //       component: () => import('@/views/information/production/resource/list.vue'),
        //       meta: {
        //         roleTitle: '资源列表',
        //         title: '',
        //         activeMenu: 'resource',
        //         buttons: informationButtons.ResourceList('ResourceList')
        //       }
        //     },
        //     {
        //       path: 'detail',
        //       name: 'ResourceDetail',
        //       component: () => import('@/views/information/production/resource/detail/detail.vue'),
        //       meta: {
        //         title: '资源详情',
        //         activeMenu: 'resource',
        //         noCache: true,
        //         nb: true
        //       }
        //     }
        //   ]
        // },
        // {
        //   path: 'shifts',
        //   name: 'Shifts',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '班次', activeMenu: 'shifts', canLink: true },
        //   redirect: '/information/factory-info/shifts/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'ShiftsList',
        //       component: () => import('@/views/information/production/shifts/list.vue'),
        //       meta: {
        //         roleTitle: '班次列表',
        //         title: '',
        //         activeMenu: 'shifts',
        //         buttons: informationButtons.ShiftsList('ShiftsList')
        //       }
        //     },
        //     {
        //       path: 'detail',
        //       name: 'ShiftsDetail',
        //       component: () => import('@/views/information/production/shifts/detail/detail.vue'),
        //       meta: {
        //         title: '班次详情',
        //         activeMenu: 'shifts',
        //         noCache: true,
        //         nb: true
        //       }
        //     }
        //   ]
        // },
        // {
        //   path: 'calendar',
        //   name: 'Calendar',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '日历', activeMenu: 'calendar', canLink: true },
        //   redirect: '/information/factory-info/calendar/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'CalendarList',
        //       component: () => import('@/views/information/production/calendar/list.vue'),
        //       meta: {
        //         roleTitle: '日历列表',
        //         title: '',
        //         activeMenu: 'calendar',
        //         buttons: informationButtons.CalendarList('CalendarList')
        //       }
        //     },
        //     {
        //       path: 'detail',
        //       name: 'CalendarDetail',
        //       component: () => import('@/views/information/production/calendar/detail/detail.vue'),
        //       meta: {
        //         title: '日历详情',
        //         activeMenu: 'calendar',
        //         noCache: true
        //       }
        //     }
        //   ]
        // }
        // {
        //   path: 'procedure-params',
        //   name: 'procedureParams',
        //   component: () => import('@/views/setting/params/procedure/procedure.vue'),
        //   meta: { title: '工序参数', icon: 'flight-managment', activeMenu: 'procedure-params', buttons: informationButtons.procedureParams('procedureParams') }
        // }
      ]
    },
    // 异常建模
    {
      path: 'abnormal-info',
      name: 'AbnormalInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '异常建模', activeMenu: 'abnormal-info' },
      redirect: '/information/abnormal-info/classify',
      children: [
        {
          path: 'classify',
          name: 'callClassify',
          component: () => import('@/views/call/classify/classify.vue'),
          meta: { title: '呼叫类型', activeMenu: 'classify', buttons: callButtons.callClassify('callClassify') }
        },
        {
          path: 'exception-setting',
          name: 'ExceptionSetting',
          component: () => import('@/views/call/exception-setting/exception-setting.vue'),
          meta: {noCache:true, title: '异常设置', activeMenu: 'exception-setting', buttons: callButtons.ExceptionSetting('ExceptionSetting') }
        }
      ]
    },
    // 客户/供应商建模  字数太多放不下 改为  往来单位建模
    {
      path: 'cusAndSup-info',
      name: 'CusAndSupInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '往来单位建模', activeMenu: 'cusAndSup-info' },
      redirect: '/information/cusAndSup-info/suppliers-info',
      children: [
        {
          path: 'suppliers-info',
          name: 'Supplier',
          component: () => import('@/views/information/unit/unit.vue'),
          meta: {
            title: '供应商',
            activeMenu: 'suppliers-info',
            buttons: informationButtons.unitInfoCommonFor('SupplierSupplier'),
            nameFlage:'Supplier'
          }
        },
        {
          path: 'customers-info',
          name: 'Customer',
          component: () => import('@/views/information/unit/unit.vue'),
          meta: {
            title: '客户',
            activeMenu: 'customers-info',
            buttons: informationButtons.unitInfoCommonFor('CustomerCustomer'),
            nameFlage:'Customer'
          }
        },
      ]
    },
    //仓库建模
    {
      path: 'warehouse-info',
      name: 'warehouseInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '仓库建模', activeMenu: 'warehouse-info' },
      redirect: '/information/warehouse-info/model',
      children: [
        {
          path: 'model',
          name: 'model',
          component: () => import('@/views/information/warehouse/warehouse.vue'),
          meta: { title: '仓库定义', activeMenu: 'model', buttons: informationButtons.model('model') }
        },
        {
          path: 'package-info',
          name: 'packageInfo',
          component: () => import('@/views/information/warehouse/package/package.vue'),
          meta: { title: '包装容器', activeMenu: 'package-info', buttons: informationButtons.packageInfo('packageInfo') }
        },
        {
          path: 'package-detail',
          name: 'packageDetail',
          component: () => import('@/views/information/warehouse/package/package-detail/package-detail.vue'),
          meta: { title: '容器详情', activeMenu: 'package-detail', nb: true },
          hidden: true
        },
        {
          path: 'tray-info',
          name: 'tray-info',
          component: () => import('@/views/information/warehouse/tray/tray.vue'),
          meta: { title: '托盘', activeMenu: 'tray-info', buttons: informationButtons.trayInfo('tray-info') }
        },
        {
          path: 'spare-parts-set',
          name: 'sparePartsSet',
          component: () => import('@/views/spareParts/spare-parts-set/spare-parts-set'),
          meta: {
            title: '出入库类型',
            activeMenu: 'spare-parts-set',
            buttons: informationButtons.sparePartsSet('sparePartsSet')
          }
        },
        {
          path: 'sit-warehouse',
          name: 'sitWarehouse',
          component: () => import('@/views/information/production/sit-warehouse/sit-warehouse.vue'),
          meta: { title: '线边仓', activeMenu: 'sit-warehouse', buttons: informationButtons.sitWarehouse('sitWarehouse') }
        },
      ]
    },
    //数据建模
    {
      path: 'data-set-info',
      name: 'DataSetInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '数据建模', activeMenu: 'data-set-info' },
      redirect: '/information/data-set-info/columns-setting',
      children: [
        {
          path: 'columns-setting',
          name: 'ColumnsSetting',
          component: () => import('@/views/columnsSetting.vue'),
          meta: { title: '数据对象', icon: 'columns-setting', activeMenu: 'columns-setting' }
        },
        {
          path: 'printing-module',
          name: 'PrintingModule',
          component: () => import('@/views/dataSetInfo/printingModule/list.vue'),
          meta: { title: '打印模板', icon: 'printing-module', activeMenu: 'printing-module' , buttons: printingModuleList('PrintingModule') }
        }
      ]
    },
    //流程建模
    {
      path: 'process-model',
      name: 'ProcessModel',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '流程建模', activeMenu: 'process-model' },
      redirect: '/information/process-model/process-file-up',
      children: [
        {
          path: 'process-file-up',
          name: 'processFileUp',
          component: () => import('@/views/uploadForProcess.vue'),
          meta: { title: '流程对象', icon: 'columns-setting', activeMenu: 'process-file-up' }
        }
      ]
    },
    // {
    //   path: 'unit',
    //   name: 'unit',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '往来单位', activeMenu: 'unit' },
    //   redirect: '/information/unit/unit-info',
    //   children: [
    //     {
    //       path: 'unit-info',
    //       name: 'unitInfo',
    //       component: () => import('@/views/information/unit/unit.vue'),
    //       meta: { title: '往来单位', activeMenu: 'unit-info', buttons: informationButtons.unitInfo('unitInfo') }
    //     }
    //   ]
    // },
    // {
    //   path: 'work-calendar',
    //   name: 'WorkCalendar',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '班次日历', activeMenu: 'work-calendar' },
    //   redirect: '/information/work-calendar/shift',
    //   children: [
    //     // {
    //     //   path: 'shift',
    //     //   name: 'shift',
    //     //   component: () => import('@/views/setting/shift/shift.vue'),
    //     //   meta: { title: '班次管理', icon: 'shift', activeMenu: 'shift', buttons: settingButtons.shift('shift') }
    //     // },
    //     {
    //       path: 'work-shift',
    //       name: 'WorkShift',
    //       component: () => import('@/views/workCalendar/workShift.vue'),
    //       meta: { title: '工作时段', activeMenu: 'work-shift', buttons: informationButtons.WorkShift('WorkShift') }
    //     }
    //   ]
    // },
    // {
    //   path: 'equipment-info',
    //   name: 'equipmentInfo',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '设备资料', activeMenu: 'equipment-info' },
    //   children: [
    //     {
    //       path: 'equipment-class',
    //       name: 'equipmentClass',
    //       component: () => import('@/views/information/equipment/equipment-class/equipment-class.vue'),
    //       meta: { title: '设备类', activeMenu: 'equipment-class', buttons: informationButtons.equipmentClass('equipmentClass') }
    //     }
    //   ]
    // },
    // {
    //   path: 'print-label',
    //   name: 'printLabel',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '标签打印', activeMenu: 'print-label' },
    //   redirect: '/information/print-label/batch-number',
    //   children: [
    //     {
    //       path: 'batch-number',
    //       name: 'batchNumber',
    //       component: () => import('@/views/information/print-label/batch-number/batch-number.vue'),
    //       meta: { title: '打印批次码', activeMenu: 'batch-number', buttons: informationButtons.batchNumber('batchNumber') }
    //     },
    //     {
    //       path: 'package-code',
    //       name: 'packageCode',
    //       component: () => import('@/views/information/print-label/package-code/package-code.vue'),
    //       meta: { title: '打印包装码', activeMenu: 'package-code', buttons: informationButtons.packageCode('packageCode') }
    //     }
    //   ]
    // },
    // {
    //   path: 'params',
    //   name: 'params',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '参数配置', icon: require('@/assets/l_c_S_images/质量.png'), activeMenu: 'params' },
    //   redirect: '/information/params/procedure-params',
    //   children: [
    //     {
    //       path: 'procedure-params',
    //       name: 'procedureParams',
    //       component: () => import('@/views/setting/params/procedure/procedure.vue'),
    //       meta: {
    //         title: '工序参数',
    //         icon: 'flight-managment',
    //         activeMenu: 'procedure-params',
    //         buttons: informationButtons.procedureParams('procedureParams')
    //       }
    //     },
    //     {
    //       path: 'scarp-reason',
    //       name: 'scarpReason',
    //       component: () => import('@/views/setting/params/scarp-reason/scarp-reason.vue'),
    //       meta: { title: '报废原因', icon: 'flight-managment', activeMenu: 'scarp-reason', buttons: informationButtons.scarpReason('scarpReason') }
    //     }
    //   ]
    // },
    // {
    //   path: 'customer',
    //   name: 'customer',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '客户管理', activeMenu: 'unit' },
    //   redirect: '/information/customer/customer-info',
    //   children: [
    //     {
    //       path: 'customer-info',
    //       name: 'customerInfo',
    //       component: () => import('@/views/information/customer/customer.vue'),
    //       meta: { title: '客户列表', activeMenu: 'customer-info', buttons: informationButtons.customerInfo('customerInfo') }
    //     }
    //   ]
    // }
  ]
}
