import authDashBoardButtons from '@/router/page-buttons/auth-dashBoard.buttons'

export default {
  path: 'statistics-board',
  name: 'statisticsBoard',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '工厂BI', icon: require('@/assets/l_c_S_images/工厂BI.svg'), activeMenu: 'statistics-board' },
  redirect: '/statistics-board/statistics-board-info/all-statistics-card',
  children: [
    {
      path: 'board-manage-new',
      name: 'boardManageNew',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '工厂看板', noCache: true, activeMenu: 'board-manage-new' },
      redirect: '/statistics-board/board-manage-new/all-board-group',
      children: [
        {
          path: 'all-board-group',
          name: 'allBoardGroup',
          component: () => import('@/views/statistics-board/board-manage/all-board-group.vue'),
          meta: { title: '默认看板', noCache: true, activeMenu: 'all-board-group', canLink: true, buttons: authDashBoardButtons.allBoardGroup }
          // redirect: '/statistics-board/board-manage-new/all-board-group/board-group',
          // children: [
          //   {
          //     hidden: true,
          //     path: 'board-group',
          //     name: 'boardGroup',
          //     component: () => import('@/views/statistics-board/board-manage/board-group/board-group.vue'),
          //     meta: { title: '看板组卡片', icon: require('@/assets/statistics-board/数据看板@2x.png'), activeMenu: 'all-board-group', buttons: authDashBoardButtons.authBoard },
          //     children: []
          //   }
          // ]
        }
      ]
    },
    // {
    //   path: 'workshop-screen',
    //   name: 'WorkshopScreen',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '车间大屏', noCache: true, activeMenu: 'workshop-screen' },
    //   redirect: '/statistics-statement/workshop-screen/prod-screen',
    //   children: [
    //     {
    //       path: 'prod-screen',
    //       name: 'ProdScreen',
    //       component: () => import('@/views/statistic-statement/workshop-screen/index.vue'),
    //       meta: { title: '生产大屏', noCache: true, activeMenu: 'prod-screen', canLink: true }
    //     }
    //   ]
    // },
    {
      path: 'statistics-board-info',
      name: 'statisticsBoardInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '统计报表', activeMenu: 'statistics-board-info' },
      redirect: '/statistics-board/statistics-board-info/all-statistics-card',
      children: [
        // {
        //   path: 'operating-reports',
        //   name: 'operatingReports',
        //   component: () => import('@/views/statistics-board/operating-reports/operating-reports.vue'),
        //   meta: { title: '运营报表', activeMenu: 'operating-reports', buttons: authDashBoardButtons.statisticsBoard }
        // },
        {
          path: 'all-statistics-card',
          name: 'allStatisticsCard',
          component: () => import('@/views/statistics-board/board-manage/all-statistics/all-statistics.vue'),
          meta: { title: '生产报表', icon: require('@/assets/statistics-board/卡片模式_块@2x.png'), activeMenu: 'all-statistics-card', buttons: authDashBoardButtons.statisticsBoard }
        },
        {
          path: 'sale-statistics-card',
          name: 'saleStatisticsCard',
          component: () => import('@/views/statistics-board/board-manage/all-statistics/sale-statistics.vue'),
          meta: { title: '售后服务报表', icon: require('@/assets/statistics-board/卡片模式_块@2x.png'), activeMenu: 'sale-statistics-card', buttons: authDashBoardButtons.saleStatisticsBoard }
        },
        {
          path: 'board-manage',
          name: 'boardManage',
          component: () => import('@/views/statistics-board/board-manage/board-manage.vue'),
          meta: { title: '自定义报表', activeMenu: 'board-manage', canLink: true }
          // redirect: '/statistics-board/statistics-board-info/board-manage/custom-statistics-card',
          // children: [
          //   {
          //     hidden: true,
          //     path: 'custom-statistics-card',
          //     name: 'customStatisticsCard',
          //     component: () => import('@/views/statistics-board/board-manage/custom-statistics/custom-statistics.vue'),
          //     meta: { title: '自定义报表卡片', icon: require('@/assets/statistics-board/自定义标题@2x.png'), activeMenu: 'board-manage' }
          //   },
          // ]
        }
      ]
    },
    // {
    //   path: 'statistics-board',
    //   name: 'statisticsBoardReport',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '统计看板', activeMenu: 'statistics-board' },
    //   redirect: '/statistics-statement/statistics-board/report-manage',
    //   children: [
    //     {
    //       path: 'report-manage',
    //       name: 'ReportManage',
    //       component: () => import('@/views/statistic-statement/statement-manage/report.vue'),
    //       meta: { title: '报表管理', icon: require('@/assets/statistics-board/卡片模式_块@2x.png'), activeMenu: 'report-manage' }
    //     }
    //   ]
    // }
  ]
}
