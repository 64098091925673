var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "none", width: "100%" },
      attrs: { id: _vm.commonIds }
    },
    _vm._l(_vm.codes, function(item, index) {
      return _c("div", { key: index }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "inline-flex",
              padding: "10px",
              border: "1px solid #000",
              "border-radius": "4px",
              width: "calc(100% - 20px)"
            }
          },
          [
            item
              ? _c(
                  "div",
                  {
                    staticClass: "left",
                    staticStyle: { border: "1px solid #000", width: "70%" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "flex",
                          "border-bottom": "1px solid #000"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              display: "inline-flex",
                              "border-right": "1px solid #000"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  padding: "2px 5px",
                                  width: "30px",
                                  "border-right": "1px solid #000"
                                }
                              },
                              [_vm._v("单号")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  padding: "2px 5px",
                                  width: "calc(100%-30px)"
                                }
                              },
                              [_vm._v(_vm._s(item.produceOrderNumber))]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              display: "inline-flex"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  padding: "2px 5px",
                                  width: "30px",
                                  "border-right": "1px solid #000"
                                }
                              },
                              [_vm._v("等级")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  padding: "2px 5px",
                                  width: "calc(100%-30px)"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.priorityStatus[item.priority].name)
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "flex",
                          "border-bottom": "1px solid #000"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              display: "inline-flex",
                              "border-right": "1px solid #000"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  padding: "2px 5px",
                                  width: "30px",
                                  "border-right": "1px solid #000"
                                }
                              },
                              [_vm._v("交期")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  padding: "2px 5px",
                                  width: "calc(100%-30px)"
                                }
                              },
                              [_vm._v(_vm._s(item.deliveryTime))]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              display: "inline-flex"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  padding: "2px 5px",
                                  width: "30px",
                                  "border-right": "1px solid #000"
                                }
                              },
                              [_vm._v("页码")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  padding: "2px 5px",
                                  width: "calc(100%-30px)"
                                }
                              },
                              [_vm._v(_vm._s(item.id))]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "flex",
                          "border-bottom": "1px solid #000"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              display: "inline-flex",
                              "border-right": "1px solid #000"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  padding: "2px 5px",
                                  width: "30px",
                                  "border-right": "1px solid #000"
                                }
                              },
                              [_vm._v("名称")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  padding: "2px 5px",
                                  width: "calc(100%-30px)"
                                }
                              },
                              [_vm._v(_vm._s(item.materialsName))]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              display: "inline-flex"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  padding: "2px 5px",
                                  width: "30px",
                                  "border-right": "1px solid #000"
                                }
                              },
                              [_vm._v("数量")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  padding: "2px 5px",
                                  width: "calc(100%-30px)"
                                }
                              },
                              [_vm._v(_vm._s(item.quantity))]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "inline-flex",
                          "border-bottom": "1px solid #000",
                          minHeight: "70px"
                        }
                      },
                      [
                        _vm._m(0, true),
                        _c("div", { staticStyle: { display: "inline-flex" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                padding: "2px 5px",
                                width: "100%",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [_vm._v(_vm._s(item.materialsSpecifications))]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "inline-flex",
                          minHeight: "80px"
                        }
                      },
                      [
                        _vm._m(1, true),
                        _c("div", { staticStyle: { display: "inline-flex" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                padding: "2px 5px",
                                width: "100%",
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [_vm._v(_vm._s(item.remark))]
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "right",
                staticStyle: { "text-align": "center", width: "30%" }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "relative",
                      left: "50%",
                      transform: "translateX(-50%)"
                    }
                  },
                  [
                    _c("div", [
                      _c("img", {
                        staticStyle: { width: "95%" },
                        attrs: {
                          src: _vm.companyInfo.logo,
                          alt: _vm.companyInfo.name
                        }
                      })
                    ]),
                    item
                      ? _c(
                          "div",
                          {
                            staticStyle: { position: "relative", top: "10px" }
                          },
                          [
                            _c("VueQr", {
                              attrs: {
                                "correct-level": 3,
                                "auto-color": false,
                                "color-dark": "#000000",
                                text: item.batchNumber,
                                size: 100,
                                margin: 0
                              }
                            }),
                            _c("div", [
                              _c(
                                "div",
                                { staticStyle: { "word-break": "break-word" } },
                                [_vm._v(_vm._s(item.batchNumber))]
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "in" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            padding: "2px 5px",
            width: "30px",
            "border-right": "1px solid #000",
            "line-height": "70px"
          }
        },
        [_vm._v("规格")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { display: "inline-flex" } }, [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            padding: "2px 5px",
            width: "30px",
            "border-right": "1px solid #000",
            "line-height": "80px"
          }
        },
        [_vm._v("备注")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }