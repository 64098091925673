import materialsApi from '@/api/information/materials'

export default {
  methods: {
    async viewMaterialsDetail(id) {
      const res = await materialsApi.getMaterialsDetailList(id)
      if (res) {
        this.$refs.editMaterials.modifyOrCopy(res, 'view')
      }
    }
  }
}
