export default {
  purchaseOrder: (name) => [
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Edit',
      title: '编辑'
    },
    {
      name: name + 'Distribute',
      title: '下发'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'End',
      title: '结束'
    },
    {
      name: name + 'Cancel',
      title: '取消'
    },
    {
      name: name + 'Add',
      title: '新建'
    }
  ]
}
