import projectButtons from './page-buttons/projectManagement.buttons'
export default {
  path: 'project-management',
  name: 'ProjectManagement',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '项目管理', icon: require('@/assets/l_c_S_images/项目管理.svg'), activeMenu: 'project-management' },
  redirect: '/project-management/file',
  children: [
    {
      path: 'file',
      name: 'File',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '项目管理', activeMenu: 'file' },
      redirect: '/project-management/file',
      children: [
        {
          path: 'project-work-bench',
          name: 'ProjectWorkBench',
          component: () => import('@/layout/blank.vue'),
          redirect: '/project-management/file/project-work-bench/list',
          meta: {  noCache: true, title: '项目工作台', activeMenu: 'project-work-bench', canLink: true},
          children: [
            {
              path: 'list',
              name: 'ProjectWorkBenchList',
              component: () => import('@/views/project/MRP/projectManagement/project-work-bench/index.vue'),
              meta: { noCache: true, title: '项目工作台', activeMenu: 'project-work-bench'},
              hidden: true
            },
            {
              path: 'adjust-project-nodes',
              name: 'AdjustProjectNodes',
              component: () => import('@/views/project/MRP/projectManagement/project-work-bench/adjustProjectNodes.vue'),
              meta: { noCache: true, title: '节点调整', activeMenu: 'project-work-bench', nb:true},
              hidden: true
            }
          ]
        },
        {
          path: 'project-info',
          name: 'ProjectInfo',
          component: () => import('@/layout/blank.vue'),
          redirect: '/project-management/file/project-info/list',
          meta: { noCache: true, title: '项目列表', activeMenu: 'project-info', canLink: true },
          children: [
            {
              path: 'list',
              name: 'ProjectManagementList',
              component: () => import('@/views/project/MRP/projectManagement/list.vue'),
              meta: { noCache: false, title: '项目列表', activeMenu: 'project-info', buttons: projectButtons.ProjectManagementList('ProjectManagementList')},
              hidden: true
            },
            {
              path: 'project-base-print',
              name: 'ProjectBasePrint',
              component: () => import('@/views/setting/base-print/project-base-print'),
              meta: { noCache: true,title: '打印机台码', activeMenu: 'project-info' },
              hidden: true
            },
            {
              path: 'add',
              name: 'AddProject',
              component: () => import('@/views/project/MRP/projectManagement/detail/add.vue'),
              meta: { noCache: false, title: '新增项目', activeMenu: 'project-info', nb: true},
              hidden: true
            },
            {
                path: 'detail',
                name: 'ProjectManagementDetail',
                component: () => import('@/views/project/MRP/projectManagement/detail/detail.vue'),
                meta: { title: '详情', noCache: true, activeMenu: 'project-info', nb: true },
                hidden: true
            }
          ]
        },
        // {
        //   path: 'plan-schedules',
        //   name: 'planSchedules',
        //   component: () => import('@/views/project/MRP/projectManagement/plan-schedules/plan-schedules.vue'),
        //   meta: { noCache: true, title: '计划甘特图', activeMenu: 'plan-schedules' , buttons: projectButtons.PlanSchedules('PlanSchedules')}
        // },
        {
          path: 'project-scheme',
          name: 'ProjectScheme',
          component: () => import('@/layout/blank.vue'),
          redirect: '/project-management/file/project-scheme/list',
          meta: { noCache: true, title: '计划方案', activeMenu: 'project-scheme', canLink: true },
          children: [
            {
              path: 'list',
              name: 'ProjectSchemeList',
              component: () => import('@/views/project/MRP/projectManagement/project-scheme/list.vue'),
              meta: { noCache: false, title: '计划方案列表', activeMenu: 'project-scheme', buttons: projectButtons.ProjectSchemeList('ProjectSchemeList')},
              hidden: true
            },
            {
                path: 'detail',
                name: 'ProjectSchemeDetail',
                component: () => import('@/views/project/MRP/projectManagement/project-scheme/detail/detail.vue'),
                meta: { title: '计划方案详情', noCache: false, activeMenu: 'project-scheme', nb: true },
                hidden: true
            }
          ]
        },
        {
          path: 'task-nodes',
          name: 'TaskNodes',
          component: () => import('@/views/project/MRP/projectManagement/task-nodes/list.vue'),
          meta: {  noCache: true, title: '节点', activeMenu: 'task-nodes', buttons: projectButtons.TaskNodes('TaskNodes')}
        },
        {
          path: 'add-task-nodes',
          name: 'AddTaskNodes',
          component: () => import('@/views/project/MRP/projectManagement/task-nodes/add.vue'),
          meta: {  noCache: true, title: '添加节点', activeMenu: 'add-task-nodes'},
          hidden:true
        },
        {
          path: 'project-task',
          name: 'ProjectTask',
          component: () => import('@/layout/blank.vue'),
          redirect: '/project-management/file/project-task/list',
          meta: { noCache: true, title: '任务列表', activeMenu: 'project-task', canLink: true },
          children: [
            {
              path: 'list',
              name: 'ProjectTaskList',
              component: () => import('@/views/project/MRP/projectManagement/project-task/list.vue'),
              meta: { noCache: false, title: '任务列表', activeMenu: 'project-task', buttons: projectButtons.ProjectTaskList('ProjectTaskList')},
              hidden: true
            },
            {
              path: 'detail',
              name: 'ProjectTaskDetail',
              component: () => import('@/views/project/MRP/projectManagement/project-task/detail/detail.vue'),
              meta: { title: '任务详情', noCache: true, activeMenu: 'project-task', buttons: projectButtons.ProjectTaskDetail('ProjectTaskDetail')},
              hidden: true
            }
          ]
        },
      ]
    }
  ]
}
